import React from "react";
import { useQuery } from "@tanstack/react-query";
import { i3_beleid, tables } from "../../api/types";
import i18n from "../../i18n";
import { fetchStrapiData } from "../../api/calls";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { Helmet } from "react-helmet-async";

const PrivacyBeleidPage = () => {
  const [data, setdata] = React.useState<i3_beleid>();

  const { data: response } = useQuery<
    i3_beleid,
    any,
    { attributes: i3_beleid },
    [string, tables]
  >([i18n.language, "i3-beleid"], () =>
    fetchStrapiData({ table: "i3-beleid" })
  );
  React.useEffect(() => {
    if (response) {
      setdata(response.attributes);
    }
  }, [response]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Privacybeleid</title>
        <meta name="robots" content="noindex"/>
        <meta
          name="description"
          content="Onze interne privacy beleid, voor vragen of merkingen kunt u ons contacteren"
        />
        <link rel="canonical" href="/privacy-beleid" />
      </Helmet>
      <div style={{ margin: "30px", paddingTop: "50px" }}>
        <h4>Privacy beleid</h4>
        <ReactMarkdown>{data?.privacy ?? ""}</ReactMarkdown>
      </div>
    </React.Fragment>
  );
};

export default PrivacyBeleidPage;
