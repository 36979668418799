import React from "react";
import { notifTypes } from "../../App";
import { colorPaller } from "../../constants/colors";
import {useWindowDimensions} from "../../hooks/useWindowsDimensions";
import {
  Button,
  Form,
  Input,
  Select,
  message,
} from "antd";
import { useTranslation } from "react-i18next";
import { Option } from "antd/es/mentions";
import TextArea from "antd/es/input/TextArea";
import { SendOutlined } from "@ant-design/icons";
import { strapiApi } from "../../api";
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet-async";

type contactForm = {
  contact: {
    email: string;
    firstName: string;
    lastName: string;
    function: string;
    phone?: string;
    vraag: string;
  };
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
const Contact = ({
  openNotificationWithIcon,
}: {
  openNotificationWithIcon: (type: notifTypes) => void;
}) => {
  const navigate = useNavigate();


  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onfinishCpntactForm = async (values: contactForm) => {
    console.log(values);
    try {
      await strapiApi({
        method: "POST",
        url: `/i3-contact-forms`,
        data: { data: values.contact },
      });
      openNotificationWithIcon('contactForm')
      navigate('/');

    } catch (error) {
      message.error(t("Er is een fout opgetreden"));
    }
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>Contactformulier</title>
        <meta name="description" content="Bij vragen of opmerkingen kunt u ons gerust contacteren via onderstaande formulier"/>
        <link rel="canonical" href="/contact"/>
      </Helmet>
      <section className="section bg-light">
        <div
          style={{
            // lineHeight: "260px",
            fontSize: 14,
            border: `3px solid ${colorPaller.blue_gradient_1}`,
            borderRadius: "15px",
            marginTop: width && width > 1400 ? 35 : 5,
            padding: 4,
            backgroundColor: "white",
            width: width && width > 1400 ? "1300px" : "90vw",
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
          }}
        >
          <Form
            {...layout}
            form={form}
            onFinish={onfinishCpntactForm}
            name="contact-form"
          >
            <div
              style={
                width && width > 1400
                  ? {
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: "100px",
                      marginRight: "100px",
                    }
                  : { display: "flex", flexDirection: "column", margin: "15px" }
              }
            >
              <div style={{ width: "100%" }}>
                <div>
                  <h2 style={{ marginBottom: "15px", marginTop: "15px" }}>
                    {t("Contact formulier")}
                  </h2>

                  <Form.Item
                    name={["contact", "firstName"]}
                    label={t("Voornaam")}
                    rules={[
                      {
                        required: true,
                        message: t("Voornaam is verplicht") ?? "",
                      },
                    ]}
                    style={
                      width && width < 1400
                        ? { margin: "0px", padding: "0px" }
                        : {}
                    }
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={["contact", "lastName"]}
                    label={t("Familienaam")}
                    rules={[
                      {
                        required: true,
                        message: t("Familienaam is verplicht") ?? "",
                      },
                    ]}
                    style={
                      width && width < 1400
                        ? { margin: "0px", padding: "0px" }
                        : {}
                    }
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={["contact", "email"]}
                    label={t("Email")}
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: t("Email is verplicht") ?? "",
                      },
                    ]}
                    style={
                      width && width < 1400
                        ? { margin: "0px", padding: "0px" }
                        : {}
                    }
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={["contact", "phone"]}
                    label={t("Telefoon nummer")}
                    rules={[
                      {
                        required: true,
                        message: t("Telefoonnummer is verplicht") ?? "",
                      },
                    ]}
                    style={
                      width && width < 1400
                        ? { margin: "0px", padding: "0px" }
                        : {}
                    }
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={["contact", "function"]}
                    rules={[
                      {
                        required: true,
                        message: t("Functie is verplicht") ?? "",
                      },
                    ]}
                    style={
                      width && width < 1400
                        ? { margin: "0px", padding: "0px" }
                        : {}
                    }
                    label={t("Functie")}
                  >
                    <Select
                      placeholder={t("Gelieve een optie te selecteren") ?? ""}
                    >
                      <Option value="werkgever">{t("Werkgever")}</Option>
                      <Option value="werknemer">{t("Werknemer")}</Option>
                      <Option value="werkzoekend">{t("Werkzoekend")}</Option>
                      <Option value="overige">{t("Overige")}</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name={["contact", "vraag"]}
                    label={t("Formulier")}
                    rules={[
                      {
                        required: true,
                        message: t("Formulier is verplicht") ?? "",
                      },
                    ]}
                    style={
                      width && width < 1400
                        ? { margin: "0px", padding: "0px" }
                        : {}
                    }
                  >
                    <TextArea rows={8} />
                  </Form.Item>
                </div>
              </div>
            </div>
            <Button
              onClick={form.submit}
              style={{
                width: "30%",
                height: "auto",
                marginBottom: "15px",
                backgroundColor: colorPaller.blue_primary,
                color: colorPaller.white,
              }}
            >
              <div>
                <SendOutlined style={{ fontSize: "25px" }} />{" "}
              </div>
              <b>{t("Indienen")}</b>
            </Button>
          </Form>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Contact;
