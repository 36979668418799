import React from 'react'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import { Routes, Route, useLocation, Outlet } from 'react-router-dom'
import UserContextProvider from './contexts/UserContexts'
import { ROUTES } from './constants/routes'
import Sollicitatie from './pages/sollicatie/Sollicitatie'
import { FloatButton, message, notification } from 'antd'
import { useTranslation } from 'react-i18next'
// import "./app.css";
// Import Scss
import './theme.scss'
import { WhatsAppOutlined, MailOutlined, InfoCircleOutlined } from '@ant-design/icons'

//Import Icon Css
import './assets/css/materialdesignicons.min.css'
import Home from './pages/home/Home'
import PrivacyBeleidPage from './pages/privacyBeleid/PrivacyBeleidPage'
import Contact from './pages/contact/Contact'
import { WerkgeversPage } from './pages/werkgever/WerkgeversPage'
import Footer from './components/Footer/Footer'
import NavbarPage from './components/Navbar/Navbar_Page'
import CookieBanner from './analytics/CookieBanner'
import CookesBeleidPage from './pages/cookiesBeleid/CookiesBeleidPage'
import ReactGA from 'react-ga4'
import useLocalStorage from './hooks/useLocalStorage'
import AllVacatures from './pages/vacature/AllVacatures'
import VacatureDetails from './pages/vacature/VacatureDetails'
import { colorPaller } from './constants/colors'
import { breakpointValues, useWindowDimensions } from './hooks/useWindowsDimensions'
// ReactGA.initialize("G-Q3Y3NJ4947");
export type notifTypes = 'endOfSollicitatieForm' | 'contactForm' | 'newsLetterSubscription'|'vacatureSollicitatie'
export interface icookiesSettings {
    analytics: boolean
    functional: boolean
    date: Date | string
    lang?: 'en' | 'nl' | 'fr'
}

function App() {
    const [api, contextHolder] = notification.useNotification()
    const { t } = useTranslation()
    const { pathname } = useLocation()

    const [cookiesSettings, setcookiesSettings, removeCookie] = useLocalStorage<icookiesSettings>('cookies_settings')
    const [showCookiesBanner, setshowCookiesBanner] = React.useState<boolean>(false)
    const [startAnalytics, setstartAnalytics] = React.useState<boolean>(false)

    const checkCookiesSettings = () => {
        try {
            console.log('app cookie settings:', cookiesSettings)
            if (!cookiesSettings) {
                setshowCookiesBanner(true)
                return
            } else if (cookiesSettings && showCookiesBanner) {
                setshowCookiesBanner(false)
            }

            let diffDays = Math.abs(new Date().getTime() - new Date(cookiesSettings.date).getTime()) / (1000 * 60 * 60 * 24)
            if (diffDays >= 365) {
                removeCookie()
            } else if (cookiesSettings?.analytics && !startAnalytics) {
                setstartAnalytics((prev) => !prev)
                ReactGA.initialize('G-Q3Y3NJ4947')
            }
        } catch (error) {
            message.error(t('Er is een fout opgetreden'))
        }
    }

    React.useEffect(() => {
        checkCookiesSettings()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cookiesSettings])

    const openNotificationWithIcon = (type: notifTypes) => {
        switch (type) {
            case 'endOfSollicitatieForm':
                api['success']({
                    message: t('Sollicitatie afgewerkt!'),
                    duration: 30,
                    description: t(
                        'Wij hebben uw sollicitatie correct ontvangen en danken u voor het vertrouwen in ons. Wij gaan hier direct mee aan de slag en u hoort ons spoedig.'
                    ),
                })
                break

            case 'contactForm':
                api['success']({
                    message: t('Contactfomrulier verzonden!'),
                    duration: 30,
                    description: t('Wij hebben uw aanvraag correct ontvangen. U hoort spoedig van ons.'),
                })
                break

            case 'newsLetterSubscription':
                api['success']({
                    message: t('Email geregistreerd'),
                    duration: 30,
                    description: t('Wij danken u voor de registratie op onze nieuwsbrief!'),
                })
                break
                case 'vacatureSollicitatie':
                    api['success']({
                        message: t('Sollicitatie geregistreerd'),
                        duration: 30,
                        description: t('Bedankt voor de Sollicitatie! U hoort spoedig van ons.'),
                    })
                    break
            default:
                break
        }
    }

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    const Layout = () => {
        const {breakpoint} = useWindowDimensions();
        return (
            <React.Fragment>
                <NavbarPage />
                <Outlet />
                <Footer />
                {breakpoint && breakpoint <= breakpointValues.sm && (
                    <FloatButton.Group trigger="click" type="primary" style={{ right: 30, color: colorPaller.blue_gradient_4 }} icon={<InfoCircleOutlined />}>
                        <FloatButton
                            tooltip={<div>Contacteer ons per e-mail</div>}
                            icon={
                                <a href="mailto: info@massarecruit.com">
                                    <MailOutlined style={{ color: colorPaller.black }} />
                                </a>
                            }
                        />
                        <FloatButton
                            tooltip={<div>Contacteer ons via Whatsapp</div>}
                            icon={
                                <a href="https://api.whatsapp.com/send?phone=32456202457" rel="noreferrer" target="_blank">
                                    <WhatsAppOutlined style={{ color: colorPaller.succes_green }} />
                                </a>
                            }
                        />
                    </FloatButton.Group>
                )}
            </React.Fragment>
        )
    }

    return (
        <I18nextProvider i18n={i18n}>
            <UserContextProvider cookiesSettings={cookiesSettings} setcookiesSettings={setcookiesSettings}>
                {contextHolder}
                {showCookiesBanner && <CookieBanner setcookiesSettings={setcookiesSettings} />}
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route path={ROUTES.SOLLICITATIE} element={<Sollicitatie openNotificationWithIcon={openNotificationWithIcon} />} />
                        <Route path={ROUTES.PRIVACYBELEID} element={<PrivacyBeleidPage />} />
                        <Route path={ROUTES.CONTACT} element={<Contact openNotificationWithIcon={openNotificationWithIcon} />} />
                        <Route path={ROUTES.VOOR_WERKGEVERS} element={<WerkgeversPage />} />
                        <Route path={ROUTES.HOME} element={<Home openNotificationWithIcon={openNotificationWithIcon} />} />
                        <Route path={ROUTES.COOKIESBELEID} element={<CookesBeleidPage cookiesSettings={cookiesSettings} removeCookie={removeCookie} />} />
                        <Route path={ROUTES.VACATURES.ALL} element={<AllVacatures />} />{' '}
                        <Route path={ROUTES.VACATURES.DETAILS} element={<VacatureDetails openNotificationWithIcon={openNotificationWithIcon} />} />
                        <Route path="*" element={<p>404</p>} />
                    </Route>
                </Routes>
            </UserContextProvider>
        </I18nextProvider>
    )
}

export default App
