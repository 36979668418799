// context/todoContext.tsx
import * as React from 'react'
import i18n from '../i18n'
import { useQueryClient } from '@tanstack/react-query'
import { fetchStrapiData } from '../api/calls'
import { tables } from '../api/types'
import { icookiesSettings } from '../App'

type possibleLanguages = 'nl' | 'fr' | 'en'

interface IUserContext {
    language?: possibleLanguages
    changeLanguage?: (lng: possibleLanguages) => void
}

export const UserContext = React.createContext<IUserContext>({})

interface Props {
    cookiesSettings?: icookiesSettings
    setcookiesSettings: (cs: icookiesSettings) => void
    children: React.ReactNode
}

const UserContextProvider: React.FC<Props> = ({ cookiesSettings, setcookiesSettings, children }) => {
    const [language, SetLanguage] = React.useState<possibleLanguages | undefined>(cookiesSettings?.lang || (i18n.language as possibleLanguages))
    const queryClient = useQueryClient()
    const [initialFetched, setinitialFetched] = React.useState<boolean>(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const changeLanguage = async (newLang: possibleLanguages) => {
        await i18n.changeLanguage(newLang)
        SetLanguage(newLang)
        if (cookiesSettings && cookiesSettings.functional && cookiesSettings.lang && cookiesSettings.lang !== newLang) {
            setcookiesSettings({ ...cookiesSettings, lang: newLang })
            console.log('setting lang in cookie')
        }
        fetchData(newLang)
    }

    React.useEffect(() => {
        console.log('init')
        if (cookiesSettings &&cookiesSettings.functional &&  cookiesSettings.lang && cookiesSettings.lang !== i18n.language) {
            console.log('setting language from cookie')
            changeLanguage(cookiesSettings?.lang)
        }
        if (!initialFetched) {
            console.log('do init fetch')
            setinitialFetched((prev) => !prev)
            fetchData(cookiesSettings?.lang ?? language ?? 'nl')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cookiesSettings, language])

    const fetchData = (lang?: possibleLanguages) => {
        console.log(`fetch data for lang ${lang ?? i18n.language}`)
        console.log(i18n.language)
        queryClient.prefetchQuery<any, any, any, [string, tables]>({
            queryKey: [lang ?? i18n.language, 'i3-sollicitatie-formulier'],
            queryFn: () => fetchStrapiData({ table: 'i3-sollicitatie-formulier' }),
        })
        queryClient.prefetchQuery<any, any, any, [string, tables]>({
            queryKey: [lang ?? i18n.language, 'i3-beleid'],
            queryFn: () => fetchStrapiData({ table: 'i3-beleid' }),
        })
    }

    return <UserContext.Provider value={{ language, changeLanguage }}>{children}</UserContext.Provider>
}

export default UserContextProvider
