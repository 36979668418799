const ROUTES = {
    HOME: '/',
    VOOR_WERKGEVERS: '/werkgever',
    CONTACT: '/contact',
    SOLLICITATIE: '/sollicitatie',
    PRIVACYBELEID: '/privacy-beleid',
    COOKIESBELEID: '/cookies',
    VACATURES: { ALL: '/vacatures', DETAILS: '/vacatures/:id' },
}
export { ROUTES }
