import { DatePicker, Form, Input, Select } from "antd";
import {useWindowDimensions} from "../../../hooks/useWindowsDimensions";
import { useTranslation } from "react-i18next";
import type { Dayjs } from "dayjs";
const { Option } = Select;

export interface Step5FormValues {
  work: {
    preferredSector: string[] | string;
    preferredSectorSpecific?: string;
    preferredSalary: string[] | string;
    travelOrMove: string;
    possibleAvailabilities: string[] | string;
    availabilityPeriod?: string;
    preferredStartDate: Dayjs | string;
    currentWorkingState: string;
    currentWorkTitle?: string;
    workExperience?: string;
    referenties?: string;
  };
}

const Step5 = ({
  form,
  onFinishStep5,
  step5Values,
}: {
  form: any;
  onFinishStep5: any;
  step5Values?: Step5FormValues;
}) => {
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const layout =
    width && width > 1400
      ? {
          labelCol: { span: 9 },
          wrapperCol: { span: 15 },
        }
      : {
          labelCol: { span: 8 },
          wrapperCol: { span: 16 },
        };
  return (
    <Form
      {...layout}
      form={form}
      initialValues={step5Values ? step5Values : undefined}
      onFinish={onFinishStep5}
      name="preference-form"
    >
      <div
        style={
          width && width > 1400
            ? {
                display: "flex",
                flexDirection: "row",
                margin: "15px",
              }
            : { display: "flex", flexDirection: "column", margin: "15px" }
        }
      >
        <div style={{ width: "100%" }}>
          <h2 style={{ marginBottom: "15px", marginTop: "15px" }}>
            {t("Voorkeur")}
          </h2>
          <Form.Item
            rules={[
              {
                required: true,
                message: t("Gelieve minstens 1 optie te selecteren") ?? "",
                type: "array",
              },
            ]}
            name={["work", "preferredSector"]}
            label={t("Sectoren")}
          >
            <Select
              mode="multiple"
              placeholder={t("Gelieve de sectoren te kiezen")}
            >
              <Option value="transportEnLogistiek">
                Transport en logistiek
              </Option>
              <Option value="bouw">{t("Bouw")}</Option>
              <Option value="itEnData">{t("IT & Data")}</Option>
              <Option value="financien">{t("Financiën")}</Option>
              <Option value="accounting">{t("Accounting")}</Option>
              <Option value="productieEnIndustrie">
                {t(
                  "Productie en industrie (fabrieken, chemische industrie, havenindustrie etc.)"
                )}
              </Option>
              <Option value="gezondheid">{t("Gezondheid")}</Option>
              <Option value="consulting">{t("Consulting")}</Option>
              <Option value="communicatie">{t("Communicatie")}</Option>
              <Option value="stem">
                {t("STEM (Science, Technology, Engineering & Mathematics)")}
              </Option>
              <Option value="retail">{t("Retail")}</Option>
              <Option value="landEnTuinbouw">{t("Land- en tuinbouw")}</Option>
              <Option value="milieu">{t("Milieu")}</Option>
              <Option value="schoonmaak">{t("Schoonmaak")}</Option>
              <Option value="techniek">{t("Techniek")}</Option>
              <Option value="installatie">
                {t(
                  "Installatie (sanitair, digitale meters, zonnepanelen, etc.)"
                )}
              </Option>
              <Option value="overige">{t("Overige")}</Option>

              <Option value="alleSectoren">{t("Alle sectoren")}</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name={["work", "preferredSectorSpecific"]}
            label={t("Overige sectoren indien specifiek")}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
          <Form.Item
            name={["work", "preferredSalary"]}
            label={t("Bruto salaris verwachting")}
            rules={[
              {
                required: true,
                message: t("Gelieve minstens 1 optie te selecteren") ?? "",
                type: "array",
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder={t("Gelieve de sectoren te kiezen")}
            >
              <Option value="<2000">
                {t("Minder dan 2000 euro per maand")}
              </Option>
              <Option value="2000-2500">
                {t("Tussen 2000 en 2500 per maand")}
              </Option>
              <Option value="2500-3000">
                {t("Tussen 2500 en 3000 per maand")}
              </Option>
              <Option value="3000-3500">
                {t("Tussen 3000 en 3500 per maand")}
              </Option>
              <Option value="3500>">{t("Meer dan 3500 per maand")}</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name={["work", "travelOrMove"]}
            label={t("Bereid om te reizen of verhuizen?")}
            rules={[
              {
                required: true,
                message: t("Gelieve een optie te selecteren") ?? "",
              },
            ]}
          >
            <Select placeholder={t("Maak uw keuze")}>
              <Option value="ja">{t("Ja, geen probleem")}</Option>
              <Option value="beperkt">{t("In beperkte mate")}</Option>
              <Option value="nee">{t("Nee")}</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name={["work", "possibleAvailabilities"]}
            label={t("Gewenste systeem")}
            rules={[
              {
                required: true,
                message: t("Gelieve minstens 1 optie te selecteren") ?? "",
                type: "array",
              },
            ]}
          >
            <Select mode="multiple" placeholder={t("Maak uw keuze")}>
              <Option value="voltijds">
                {t("Voltijds (37-40 uur per week)")}
              </Option>
              <Option value="4/5">{t("4/5 (30 uur per week)")}</Option>
              <Option value="deeltijds">
                {t("Deeltijds (20-30 uur per week)")}
              </Option>
              <Option value="weekendWerk">{t("Weekend werk")}</Option>
              <Option value="avondWerk">{t("Avond werk")}</Option>
              <Option value="nachtDienst">{t("Nachtdiensten")}</Option>
              <Option value="ploegSysteem">{t("Ploegen systeem")}</Option>
              <Option value="flexibel">{t("Flexibele uren")}</Option>
              <Option value="tijdelijk">
                {t("Tijdelijk werk (bepaalde periode)")}
              </Option>
              <Option value="projectMatig">
                {t("Projectmatig werk (voor een specifiek project)")}
              </Option>
              <Option value="flexijob">{t("Flexijob")}</Option>
              <Option value="student">{t("Studentenjob")}</Option>
              <Option value="overige">{t("Overige")}</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name={["work", "availabilityPeriod"]}
            label={t("Beschikbaarheid indien specifiek")}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name={["work", "preferredStartDate"]}
            rules={[
              {
                required: true,
                message: t("Gelieve een datum te selecteren") ?? "",
              },
            ]}
            label={t("Startdatum")}
            wrapperCol={{ span: 6 }}
          >
            <DatePicker format={"DD/MM/YYYY"} />
          </Form.Item>
        </div>
        <div style={{ width: "100%" }}>
          <h2 style={{ marginBottom: "15px", marginTop: "15px" }}>
            {t("Actueel")}
          </h2>

          <Form.Item
            name={["work", "currentWorkingState"]}
            label={t("Huidige statuut")}
            rules={[
              {
                required: true,
                message: t("Gelieve uw keuze te maken") ?? "",
              },
            ]}
          >
            <Select placeholder={t("Maak uw keuze")}>
              <Option value="student">{t("Student")}</Option>
              <Option value="arbeider">{t("Arbeider")}</Option>
              <Option value="bediende">{t("Bediende")}</Option>
              <Option value="flexijob">{t("Flexi-job")}</Option>
              <Option value="freelance">{t("Freelance")}</Option>
              <Option value="werkstudent">{t("Werkstudent")}</Option>
              <Option value="werkzoekend">{t("Werkzoekend")}</Option>
              <Option value="Andere">{t("Andere")}</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name={["work", "currentWorkTitle"]}
            label={t("Huidige functie titel")}
            help={t("'Geen' indien werkzoekend")}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={["work", "workExperience"]}
            label={t("Werkervaring")}
            help={t("'Geen' indien werkzoekend/schoolverlater")}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item
            name={["work", "referenties"]}
            label={t("Referentiegegevens")}
            help={t("Naam, functie en tel.nr. indien referenties mogelijk")}
          >
            <Input.TextArea />
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

export default Step5;
