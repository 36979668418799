import { Form, Input, message } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Button } from "reactstrap";
import { notifTypes } from "../../App";
import { strapiApi } from "../../api";
import { useNavigate } from "react-router";
import { colorPaller } from "../../constants/colors";

const Abboneer = ({
    openNotificationWithIcon,
  }: {
    openNotificationWithIcon: (t: notifTypes) => void;
  }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const saveEmail = async (values: {email:string}) => {
    console.log(values);


    try {
        await strapiApi({
          method: "POST",
          url: `/i3-subscribers`,
          data: { data: values },
        });
        openNotificationWithIcon('newsLetterSubscription')
        navigate('/');
  
      } catch (error) {
        message.error(t("Er is een fout opgetreden"));
      }

    };


  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="text-center mb-5">
                <h5 className="text-primary text-uppercase small-title">
                  {t("Subscribe")}
                </h5>
                <h4 className="mb-3">{t("Abboneer op onze nieuwsbrief")}</h4>
                <p>
                  {t(
                    "Mis geen enkele opportuniteit en blijf op de hoogte van ons aanbod!"
                  )}
                </p>
              </div>
            </Col>

            <Col xl={8} lg={10}>
              <div className="text-center">
                <div className="subscribe">
                  <Form form={form} onFinish={saveEmail}>
                    <Row>
                      <Col md={9}>
                        <div>
                          <Form.Item
                            name={"email"}
                            rules={[
                              {
                                type: "email",
                                required: true,
                                message:
                                  t(
                                    "Gelieve een correcte email in te voeren"
                                  ) ?? "",
                              },
                            ]}
                          >
                            <Input
                              placeholder={
                                t("Gelieve uw email adress in te geven") ?? ""
                              }
                              style={{height:"46px"}}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mt-3 mt-md-0">
                          <Button type="submit" style={{backgroundColor:colorPaller.blue_primary}} block>
                            {t("Abonneren")}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Abboneer;
