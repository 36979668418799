import React from 'react';
import { Row, Col } from "reactstrap";

const SectionTitle = ({subtitle, title, desc, bold}:{subtitle?: string, title?: string, desc?: string, bold?: boolean }) => {
  return (
  
        <React.Fragment>
            <Row className="justify-content-center">
                <Col lg={8}>
                    <div className="text-center mb-5">
                        <h5 className="text-primary text-uppercase small-title" style={bold ?{fontWeight:'bolder'}:{}}>{subtitle??""}</h5>
                        <h4 className="mb-3" style={bold ?{fontWeight:'bolder'}:{}}>{title}</h4>
                        <p style={bold ?{fontWeight:'bolder'}:{}}>{desc}</p>
                    </div>
                </Col>
            </Row>
        </React.Fragment>  )
}

export default SectionTitle
