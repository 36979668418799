import React from 'react'
import { DatePicker, Form, Input, InputNumber, Radio, Select } from 'antd'
import { useWindowDimensions } from '../../../hooks/useWindowsDimensions'
import { useTranslation } from 'react-i18next'
import { Option } from 'antd/es/mentions'
import type { Dayjs } from 'dayjs'
import TextArea from 'antd/es/input/TextArea'
export interface Step2FormValues {
    user: {
        email: string
        firstName: string
        lastName: string
        phone: string
        birthDate: Dayjs | string
        adressCity: string
        adressStreetName: string
        adressHouseNumber: string | number
        adressHouseNumberBus?: string
        adressPostalCode: string
        currentLegalState: string
        driversLicense: string
        driversLicenseExtra?: string
        nationality: string
        workExtra?: string
        personalExtra?: string
        student: string
    }
}

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
}

const Step2 = ({ form, onFinishStep2, step2Values }: { form: any; onFinishStep2: any; step2Values?: Step2FormValues }) => {
    const { width } = useWindowDimensions()
    const { t } = useTranslation()

    return (
        <Form {...layout} form={form} initialValues={step2Values ? step2Values : undefined} onFinish={onFinishStep2} name="nest-messages">
            <div
                style={
                    width && width > 1400
                        ? {
                              display: 'flex',
                              flexDirection: 'row',
                              margin: '15px',
                          }
                        : { display: 'flex', flexDirection: 'column', margin: '15px' }
                }
            >
                <div style={{ width: '100%' }}>
                    <div>
                        <h2 style={{ marginBottom: '15px', marginTop: '15px' }}>{t('Persoonsgegevens')}</h2>
                        <Form.Item
                            name={['user', 'email']}
                            label={t('Email')}
                            rules={[
                                {
                                    type: 'email',
                                    required: true,
                                    message: t('Email is verplicht') ?? '',
                                },
                            ]}
                            style={width && width < 1400 ? { margin: '0px', padding: '0px' } : {}}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name={['user', 'firstName']}
                            label={t('Voornaam')}
                            rules={[{ required: true, message: t('Voornaam is verplicht') ?? '' }]}
                            style={width && width < 1400 ? { margin: '0px', padding: '0px' } : {}}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name={['user', 'lastName']}
                            label={t('Familienaam')}
                            rules={[
                                {
                                    required: true,
                                    message: t('Familienaam is verplicht') ?? '',
                                },
                            ]}
                            style={width && width < 1400 ? { margin: '0px', padding: '0px' } : {}}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name={['user', 'phone']}
                            rules={[
                                {
                                    required: true,
                                    message: t('Telefoon nummer is verplicht') ?? '',
                                },
                            ]}
                            label={t('Telefoonnummer')}
                            style={width && width < 1400 ? { margin: '0px', padding: '0px' } : {}}
                        >
                            <Input />
                        </Form.Item>
                    </div>
                    <div>
                        <div>
                            <h2 style={{ marginBottom: '15px', marginTop: '15px' }}>{t('Adres')}</h2>
                            <Form.Item
                                name={['user', 'adressStreetName']}
                                label={t('Straatnaam')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('Straatnaam is verplicht') ?? '',
                                    },
                                ]}
                                style={width && width < 1400 ? { margin: '0px', padding: '0px' } : {}}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={['user', 'adressHouseNumber']}
                                label={t('Huisnummer')}
                                rules={[
                                    {
                                        type: 'number',
                                        min: 0,
                                        required: true,
                                        message: t('Huisnummer is verplicht') ?? '',
                                    },
                                ]}
                                style={width && width < 1400 ? { margin: '0px', padding: '0px' } : {}}
                            >
                                <InputNumber style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item
                                name={['user', 'adressHouseNumberBus']}
                                label={t('Bus')}
                                style={width && width < 1400 ? { margin: '0px', padding: '0px' } : {}}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={['user', 'adressPostalCode']}
                                rules={[{ required: true, message: t('Postcode is verplicht') ?? '' }]}
                                label={t('Postcode')}
                                style={width && width < 1400 ? { margin: '0px', padding: '0px' } : {}}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={['user', 'adressCity']}
                                rules={[{ required: true, message: t('Stad is verplicht') ?? '' }]}
                                label={t('Stad')}
                                style={width && width < 1400 ? { margin: '0px', padding: '0px' } : {}}
                            >
                                <Input />
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div style={{ width: '100%' }}>
                    {' '}
                    <h2 style={{ marginBottom: '15px', marginTop: '15px' }}>{t('Overige')}</h2>
                    <Form.Item
                        name={['user', 'nationality']}
                        rules={[
                            {
                                required: true,
                                message: t('Nationaliteit is verplicht') ?? '',
                            },
                        ]}
                        style={width && width < 1400 ? { margin: '0px', padding: '0px' } : {}}
                        label={t('Nationaliteit')}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={['user', 'currentLegalState']}
                        rules={[
                            {
                                required: true,
                                message: t('Burgelijke stand is verplicht') ?? '',
                            },
                        ]}
                        style={width && width < 1400 ? { margin: '0px', padding: '0px' } : {}}
                        label={t('Burgelijke staat')}
                    >
                        <Select placeholder={t('Gelieve een optie te selecteren') ?? ''}>
                            <Option value="gehuwd">{t('Gehuwd')}</Option>
                            <Option value="ongehuwd">{t('Ongehuwd')}</Option>
                            <Option value="samenwonend">{t('Samenwonend')}</Option>
                            <Option value="gescheiden">{t('Gescheiden')}</Option>
                            <Option value="overige">{t('Overige')}</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name={['user', 'birthDate']}
                        rules={[
                            {
                                required: true,
                                message: t('Geboortedatum is verplicht') ?? '',
                            },
                        ]}
                        style={width && width < 1400 ? { margin: '0px', padding: '0px' } : {}}
                        label={t('Geboortedatum')}
                    >
                        <DatePicker format={'DD/MM/YYYY'} placeholder="dd/mm/yyyyy" showToday={false} />
                    </Form.Item>
                    <Form.Item
                        name={['user', 'driversLicense']}
                        rules={[{ required: true, message: t('Rijbewijs is verplicht') ?? '' }]}
                        style={width && width < 1400 ? { margin: '0px', padding: '0px' } : {}}
                        label={t('Rijbewijs B')}
                    >
                        <Radio.Group>
                            <Radio value="ja">{t('Ja')}</Radio>
                            <Radio value="nee">{t('Nee')}</Radio>
                            <Radio value="voorlopig">{t('Voorlopig rijbewijs')}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name={['user', 'driversLicenseExtra']}
                        style={width && width < 1400 ? { margin: '0px', padding: '0px' } : {}}
                        label={t('Overige rijbewijzen')}
                    >
                        <Input placeholder={t('Bv. Rijbewijs C') ?? ''} />
                    </Form.Item>
                    <Form.Item
                        name={['user', 'student']}
                        style={width && width < 1400 ? { margin: '0px', padding: '0px' } : {}}
                        rules={[
                            {
                                required: true,
                                message: t('Gelieve uw keuze te maken') ?? '',
                            },
                        ]}
                        label={t('Student')}
                        initialValue={'nee'}
                    >
                        <Radio.Group>
                            <Radio value="ja">{t('Ja')}</Radio>
                            <Radio defaultChecked={true} value="nee">
                                {t('Nee')}
                            </Radio>
                        </Radio.Group>{' '}
                    </Form.Item>
                    <Form.Item
                        name={['user', 'workExtra']}
                        style={width && width < 1400 ? { margin: '0px', padding: '0px' } : {}}
                        label={t('Welke uitdaging zoekt u?')}
                        labelCol={{ push: 2, span: 24 }}
                        wrapperCol={{ push: 1, span: 23 }}
                    >
                        <TextArea
                            placeholder={
                                t(
                                    'Bv. Ik ben een ervaren technieker dat op zoek is naar een nieuwe werkgever\nBv. Ik ben net afgestudeerd en wil graag werken als developer.'
                                ) ?? ''
                            }
                            rows={4}
                        />
                    </Form.Item>
                    <Form.Item
                        name={['user', 'personalExtra']}
                        style={width && width < 1400 ? { margin: '0px', padding: '0px' } : {}}
                        label={t('Extra opmerking')}
                        labelCol={{ push: 2, span: 24 }}
                        wrapperCol={{ push: 1, span: 23 }}
                    >
                        <TextArea placeholder={t('Extra opmerkingen, belangerijke zaken,...') ?? ''} rows={2} />
                    </Form.Item>
                </div>
            </div>
        </Form>
    )
}

export default Step2
