import React from 'react'
import { Container, Row, Col, Card, CardBody, CardHeader } from 'reactstrap'
//Import Images
import feature4 from '../../assets/images/features/img-6.png'
import feature3 from '../../assets/images/features/img-5.png'

//Import Section Title
import SectionTitle from '../common/SectionTitle'
import FeatureBox from './feature-box'
import { useTranslation } from 'react-i18next'
import Banner1 from '../banner1/Banner1'
const AboutUs = () => {
    const { t } = useTranslation()

    const smallFeatures1 = [{ title: t('Transparantie') }, { title: t('Ondersteuning') }]

    const smallFeatures2 = [{ title: t('Doelgericht') }, { title: t('Duurzaamheid als sleutel') }]
    return (
        <React.Fragment>
            <section className="section" id="overOns">
                <Container>
                    <SectionTitle
                        subtitle={t('Over Ons') ?? ''}
                        title={t('Een ruime scala aan sectoren') ?? ''}
                        desc={
                            t(
                                'Wij gaan als selectie- en adviesbureau op zoek naar de ‘perfect match’. Tussen de kandidaat en de job zelf, maar ook tussen de kandidaat en de werkgever. '
                            ) ?? ''
                        }
                    />

                    <Row>
                        <Col lg={5}>
                            <div>
                                <FeatureBox
                                    icon="bar-chart-2"
                                    title={t('Missie')}
                                    smallFeatures={smallFeatures1}
                                    desc={t(
                                        "Bij Massarecruit fungeren we als een brug tussen werknemers en werkgevers in een wereld dat voortdurend evolueert. Onze missie is om sociaal-economische waarde te creëren door middel van duurzame groei. Massarecruit gaat vastberaden de strijd aan met uitdagingen zoals de voortdurende groei van knelpuntberoepen, 'War For Talent' en het tekort aan arbeidskrachten. We streven ernaar om werkgevers te ondersteunen bij het aantrekken en behouden van gekwalificeerd personeel, terwijl we tegelijkertijd een omgeving creëren waarin werknemers zich thuis kunnen voelen. Bij het vervullen van onze missie hechten we veel belang aan ethiek, diversiteit en gelijke kansen"
                                    )}
                                />
                            </div>
                        </Col>

                        <Col lg={5} sm={8} className="ml-lg-auto">
                            <Card className="border border-light shadow-none mt-5 mt-lg-0">
                                <CardHeader className="border-0 bg-transparent"></CardHeader>
                                <CardBody className="bg-light">
                                    <div className="box-shadow">
                                        <img src={feature3} alt="" className="img-fluid mx-auto d-block" />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mt-5 pt-5">
                        <Col lg={5} className="ml-lg-auto">
                            <div className="mt-4 mt-lg-0">
                                <FeatureBox
                                    icon="pie-chart"
                                    title={t('Visie')}
                                    smallFeatures={smallFeatures2}
                                    desc={t('Samen met verschillende generaties bijdragen aan de wereld van morgen')}
                                />
                            </div>
                        </Col>
                        <Col lg={5} sm={8}>
                            <Card className="border border-light shadow-none">
                                <CardHeader className=" border-0 bg-transparent"></CardHeader>
                                <CardBody className="bg-light">
                                    <div className="box-shadow">
                                        <img src={feature4} alt="" className="img-fluid mx-auto d-block" />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Banner1 />
        </React.Fragment>
    )
}

export default AboutUs
