import './loader.scss'
import logodark from '../../assets/images/logo/logo2.png'

const Loader = () => {
    return (
        <div className="loader-container">
            <div className="loader">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <img src={logodark} alt="logo" />
        </div>
    )
}

export default Loader
