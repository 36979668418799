import React from "react";
import { Container } from "reactstrap";
import SectionTitle from "../common/SectionTitle";
import { useTranslation } from "react-i18next";
import logodark from "../../assets/images/logo/logo2.png";

const Banner1 = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <section className="section custom-banner-style">
        <Container>
          <SectionTitle
            subtitle={t("Ben je klaar voor de volgende stap in je carrière?")??''}
            title={t(
              "Als toonaangevend recruitmentbureau zijn wij bij MassaRecruit voortdurend op zoek naar getalenteerde individuen uit alle hoeken van de arbeidsmarkt."
            )??''}
            bold={true}
          />

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <img src={logodark} alt="" className="logo" height="40" />
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Banner1;
