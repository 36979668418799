import React, { useContext } from "react";
import { colorPaller } from "../../../constants/colors";
import { useTranslation } from "react-i18next";
import { i3_sollicitatie_formulier, tables } from "../../../api/types";
import { useQuery } from "@tanstack/react-query";
import i18n from "../../../i18n";
import { fetchStrapiData } from "../../../api/calls";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { UserContext } from "../../../contexts/UserContexts";

const Step1 = () => {
  const { t } = useTranslation();
  const [data, setdata] = React.useState<i3_sollicitatie_formulier>();
  const { changeLanguage, language } = useContext(UserContext);

  const { data: response } = useQuery<
    i3_sollicitatie_formulier,
    any,
    { attributes: i3_sollicitatie_formulier },
    [string, tables]
  >([language ?? i18n.language, "i3-sollicitatie-formulier"], () =>
    fetchStrapiData({ table: "i3-sollicitatie-formulier" })
  );
  React.useEffect(() => {
    if (response) {
      setdata(response.attributes);
    }
  }, [response]);
  return (
    <div style={{ fontWeight: "bold" }}>
      <h2>{t("Sollicitatieformulier")}</h2>

      <div
        style={{
          textAlign: "left",
          marginLeft: "10px",
          marginRight: "10px",
          color: colorPaller.black,
        }}
      >
        <ReactMarkdown>{data?.intro ?? ""}</ReactMarkdown>
      </div>

      <p>
        {t("Taal")}:
        <select
          style={{
            backgroundColor: "transparent",
            border: "2px",
            color: colorPaller.blue_gradient_1,
            fontWeight: "bold",
            width: "40px",
            height: "35px",
            appearance: "none",
            textAlign: "center",
          }}
          onChange={(e) => {
            changeLanguage &&
              changeLanguage(e.target.value as "fr" | "nl" | "en");
          }}
          value={language}
        >
          <option value={"nl"}>NL</option>
          <option value={"fr"}>FR</option>
          <option value={"en"}>EN</option>
        </select>
      </p>
    </div>
  );
};

export default Step1;
