import { useCallback, useState } from 'react'

export default function useLocalStorage<T>(key: string) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
        if (typeof window === 'undefined') {
            return undefined
        }
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key)
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : undefined
        } catch (error) {
            // If error also return initialValue
            console.log(error)
            return undefined
        }
    })
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = useCallback(
        (value: T | ((val: T) => T)) => {
            try {
                // Allow value to be a function so we have same API as useState
                const valueToStore = value instanceof Function ? value(storedValue) : value
                // Save state
                setStoredValue(valueToStore)
                // Save to local storage
                if (typeof window !== 'undefined') {
                    window.localStorage.setItem(key, JSON.stringify(valueToStore))
                }
                if (!valueToStore) window.localStorage.removeItem(key)
            } catch (error) {
                // A more advanced implementation would handle the error case
                console.log(error)
            }
        },
        [key, storedValue]
    )
    const removeCookie = () => {
        try {
            // Save to local storage
            if (typeof window !== 'undefined') {
                window.localStorage.removeItem(key)
                setStoredValue(undefined as any)
            }
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error)
        }
    }

    return [storedValue, setValue, removeCookie] as const
}
