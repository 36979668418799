import { useState, useEffect } from 'react'
export enum breakpointValues {
    xs = 1, // >544px
    sm = 2, // 544px-768px
    md = 3, // 768px-1012px
    lg = 4, // 1012px-1280px
    xl = 5, // 1200px<
}

export const useWindowDimensions = () => {
    const hasWindow = typeof window !== 'undefined'

    const getWindowDimensions = () => {
        const width = hasWindow ? window.innerWidth : undefined
        const height = hasWindow ? window.innerHeight : undefined
        const breakpoint = width
            ? width > 544
                ? width > 768
                    ? width > 1012
                        ? width > 1200
                            ? breakpointValues.xl
                            : breakpointValues.lg
                        : breakpointValues.md
                    : breakpointValues.sm
                : breakpointValues.xs
            : undefined
        return {
            width,
            height,
            breakpoint,
        }
    }

    const [windowDimensions, setWindowDimensions] = useState<{
        width?: number
        height?: number
        breakpoint?: breakpointValues
    }>(getWindowDimensions())

    const handleResize = () => {
        setWindowDimensions(getWindowDimensions())
    }

    useEffect(() => {
        if (hasWindow) {
            window.addEventListener('resize', handleResize)
            return () => window.removeEventListener('resize', handleResize)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasWindow])

    return windowDimensions
}
