import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import commonEn from './en/common.json';
import commonFr from './fr/common.json';
import commonNl from './nl/common.json';

const i18n = i18next.createInstance();

i18n.use(initReactI18next).init({
    fallbackLng: 'nl',
    debug: false,
    initImmediate: false,
    interpolation: {
        escapeValue: false,
    },
    resources: {
        nl: {
            translation: commonNl,
        },
        fr: {
            translation: commonFr,
        },
        en: {
            translation: commonEn,
        },
    },
});

export default i18n;
