import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from "reactstrap";
import { colorPaller } from '../../constants/colors';
import logodark from '../../assets/images/logo/logo2.png'

const HomeSection = () => {
    const { t } = useTranslation();

  return (
            <React.Fragment>
                <section className="hero-section-2" style={{backgroundColor:colorPaller.blue_primary}} id="home">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={8}>
                                <div className="hero-wrapper text-center mb-4">
                                    <img src={logodark}  alt="" className="logo" height="40" style={{marginBottom:"15px", border:"2px solid white"}} />
                                    <h1 className="hero-title text-white mb-4">{t("Wij bieden een job aan die bij jou past")} </h1>

                                    <p style={{color:colorPaller.blue_gradient_4}}>{t("Als toonaangevend recruitmentbureau zijn wij bij MassaRecruit voortdurend op zoek naar getalenteerde individuen uit alle hoeken van de arbeidsmarkt")}</p>

                                </div>

                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
  )
}

export default HomeSection