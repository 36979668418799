import React, { useState } from 'react'
import { Button, Form, Input, Modal, message } from 'antd'
import { colorPaller } from '../../constants/colors'
import { SendOutlined } from '@ant-design/icons'
import { notifTypes } from '../../App'
import { strapiApi } from '../../api'
import { i3_vacature } from '../../api/types'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'

interface modalprops {
    setShowSollicitatieModal: (v: boolean) => void
    ShowSollicitatieModal: boolean
    openNotificationWithIcon: (type: notifTypes) => void
    vacature?: { attributes: i3_vacature; id: number }
}
interface emailFormValues {
    email: string
}

const SollicitatieModal = ({ ShowSollicitatieModal, setShowSollicitatieModal, openNotificationWithIcon, vacature }: modalprops) => {
    const [loading, setLoading] = useState(false)
    const [emailForm] = Form.useForm()
    const navigate = useNavigate()

    const handleFinish = async (values: emailFormValues) => {
        if(loading) return;
        console.log(values)
        setLoading(true)
        try {
            await strapiApi({
                method: 'POST',
                url: `/i3-vacature-sollicitaties`,
                data: {
                    data: {
                        email: values.email,
                        vacature_name: vacature?.attributes?.main_title,
                        vacature_id: vacature?.id,
                    },
                },
            })
            openNotificationWithIcon('vacatureSollicitatie')
        } catch (error) {
            message.error('Er is een fout opgetreden')
        }
        setLoading(false)
        setShowSollicitatieModal(false)
    }
    const handleCancel = () => {
        setShowSollicitatieModal(false)
    }

    return (
        <Modal
            open={ShowSollicitatieModal}
            title="Sollicitatie"
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Keer Terug
                </Button>,
            ]}
        >
            <b>Heeft u zicht al reeds ingeschreven via ons sollicitatieformulier?</b>
            <p>
                <b>Nee</b>: gelieve dit eerst te doen zodat wij voldoende informatie hebben
            </p>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                    style={{
                        height: 'auto',
                        marginBottom: '15px',
                        backgroundColor: colorPaller.blue_primary,
                        color: colorPaller.white,
                    }}
                    onClick={() => navigate(ROUTES.SOLLICITATIE)}
                    >
                    <b>Ga naar sollicitatieformulier</b>
                </Button>
            </div>
            <p>
                <b>Ja</b>: gelieve hieronder uw email adress in te geven:
            </p>
            <Form form={emailForm} onFinish={handleFinish}>
                <Form.Item
                    name={['email']}
                    label="Email"
                    rules={[
                        {
                            type: 'email',
                            required: true,
                            message: 'Email is verplicht',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button
                        style={{
                            height: 'auto',
                            marginBottom: '15px',
                            backgroundColor: colorPaller.blue_primary,
                            color: colorPaller.white,
                        }}
                        onClick={() => emailForm.submit()}
                    >
                        <div>
                            <SendOutlined style={{ fontSize: '25px' }} />{' '}
                        </div>
                        <b>Solliciteer</b>
                    </Button>
                </div>
            </Form>
        </Modal>
    )
}

export default SollicitatieModal
