import axios from "axios";

const strapiApi = axios.create({
  timeout: 20000,
  baseURL: "https://strapi-t2wv5rjn7a-ew.a.run.app/api",
  headers: {
    Authorization: "bearer d284a660b0a9318f500bb8d1dd6767e14f6b095d1dadf18f1a96e333557a3ee2fb4c86fb240611a262374d3505383ffdc62c0512da47e74e993e4f2307217f1dd683f54a371bf92c875a1420f864854d87c63d5de79e50770b87a4467dd22f58b0e1ff0a0c5d48700d0413ad50e7228943d7a28c16bd183ef39189649d5b045f",
  },
});
const strapiApiProduction = axios.create({
  timeout: 20000,
  baseURL: "https://strapi-t2wv5rjn7a-ew.a.run.app/api",
  headers: {
    Authorization:
      "bearer d284a660b0a9318f500bb8d1dd6767e14f6b095d1dadf18f1a96e333557a3ee2fb4c86fb240611a262374d3505383ffdc62c0512da47e74e993e4f2307217f1dd683f54a371bf92c875a1420f864854d87c63d5de79e50770b87a4467dd22f58b0e1ff0a0c5d48700d0413ad50e7228943d7a28c16bd183ef39189649d5b045f",
  },
});


export { strapiApi, strapiApiProduction };
