import { strapiApi, strapiApiProduction } from '.'
import i18n from '../i18n'
import { customRoutes, tables } from './types'

interface fetchDataProps {
    table: tables
    id?: number
    populate?: string
    useProduction?: boolean
    sortIdDesc?: boolean
    returnMeta?: boolean
    pagination?: { page?: number; pageSize?: number }
    customRoute?: customRoutes,
    query?:string
}

export const fetchStrapiData = async (props: fetchDataProps) => {
    try {
        let queries = ''
        let firstQueryWritten = false
        if (props.id) queries += `/${props.id}`
        if (i18n.language) {
            queries += `${firstQueryWritten ? '&' : '?'}locale=${i18n.language}`
            if (!firstQueryWritten) firstQueryWritten = true
        }
        if (props.populate) {
            queries += `${firstQueryWritten ? '&' : '?'}populate=${props.populate}`
            if (!firstQueryWritten) firstQueryWritten = true
        }
        if (props.sortIdDesc) {
            queries += `${firstQueryWritten ? '&' : '?'}sort[0]=id%3Adesc`
            if (!firstQueryWritten) firstQueryWritten = true
        }
        if (props.query) {
            queries += `${firstQueryWritten ? '&' : '?'}${props.query}`
            if (!firstQueryWritten) firstQueryWritten = true
        }
        if (props.pagination) {
            if (props.pagination.page) {
                queries += `${firstQueryWritten ? '&' : '?'}pagination[page]=${props.pagination.page}`
                if (!firstQueryWritten) firstQueryWritten = true
            }
            if (props.pagination.pageSize) {
                queries += `${firstQueryWritten ? '&' : '?'}pagination[pageSize]=${props.pagination.pageSize}`
                if (!firstQueryWritten) firstQueryWritten = true
            }
        }
        let result
        if (props.useProduction) {
            result = await strapiApiProduction({
                method: 'GET',
                url: `/${props.table}${props.customRoute ? `/${props.customRoute}` : ''}${queries}`,
            })
        } else {
            result = await strapiApi({
                method: 'GET',
                url: `/${props.table}${props.customRoute ? `/${props.customRoute}` : ''}${queries}`,
            })
        }
        if (props.returnMeta) {
            return result.data
        }
        if (props.customRoute) {
            return result.data
        }
        return result.data.data
    } catch (e: any) {
        console.error(e)
    }
}
