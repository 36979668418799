import React from 'react'
import { Container, Row, Col } from 'reactstrap'

//Import Section Title
import SectionTitle from '../common/SectionTitle'
import { useTranslation } from 'react-i18next'
import { i3_vacature, meta_data, tables } from '../../api/types'
import { useQuery } from '@tanstack/react-query'
import { fetchStrapiData } from '../../api/calls'
import { breakpointValues, useWindowDimensions } from '../../hooks/useWindowsDimensions'
import { colorPaller } from '../../constants/colors'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'

const PaarVacatures = () => {
    const { t } = useTranslation()
    const { breakpoint } = useWindowDimensions()
    const [data, setdata] = React.useState<{ attributes: i3_vacature; id: number }[]>()
    const navigate = useNavigate()

    const { data: response } = useQuery<i3_vacature, any, { data: { attributes: i3_vacature; id: number }[]; meta: meta_data }, [tables]>(
        ['i3-vacatures'],
        () => fetchStrapiData({ table: 'i3-vacatures', populate: '*', sortIdDesc: true, returnMeta: true, pagination: { page: 0, pageSize: 4 } })
    )
    React.useEffect(() => {
        if (response) {
            setdata(response.data)
        }
    }, [response])

    return (
        <React.Fragment>
            <section className="section  section-few-jobs" id="vacatures">
                <Container>
                    <SectionTitle subtitle={t('Vacatures') ?? ''} title={t('Een greep uit ons aanbod') ?? ''} />
                    <Row>
                        {data &&
                            data.length &&
                            data.map((vac, count) =>
                                count === 3 && breakpoint && breakpoint === breakpointValues.xl ? (
                                    ''
                                ) : (
                                    <Col sm={12} md={6} xl={4}>
                                        <div className="recent-job-item" style={{ overflow: 'auto' }}>
                                            <div className="company-info">
                                                <div className="content">
                                                    <h4 className="name" style={{ color: colorPaller.blue_primary }}>
                                                        {vac?.attributes?.client_city}
                                                    </h4>
                                                    <p className="address" style={{ color: colorPaller.blue_gradient_2 }}>
                                                        {vac.attributes.provincie}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="main-content">
                                                <h3 className="title" style={{ color: colorPaller.blue_primary }}>
                                                    {vac?.attributes.main_title}
                                                </h3>
                                                <h5 className="work-type" style={{ color: colorPaller.blue_gradient_2 }}>
                                                    {vac?.attributes?.contract?.map((t, index) => (
                                                        <span>
                                                            {' '}
                                                            {index === 0 ? '' : ', '}
                                                            {t.text_field}{' '}
                                                        </span>
                                                    ))}
                                                </h5>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Button
                                                    style={{
                                                        width: '50%',
                                                        height: 'auto',
                                                        marginTop: '15px',
                                                        backgroundColor: colorPaller.blue_primary,
                                                        color: colorPaller.white,
                                                    }}
                                                    onClick={() => navigate(`/vacatures/${vac.id}`)}

                                                >
                                                    <b>{t('Meer info')}</b>
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            )}
                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                            style={{
                                width: '50%',
                                height: 'auto',
                                marginTop: '15px',
                                backgroundColor: colorPaller.blue_primary,
                                color: colorPaller.white,
                            }}
                            onClick={() => navigate(ROUTES.VACATURES.ALL)}
                        >
                            <b>{t('Toon mij alle vacatures')}</b>
                        </Button>
                    </div>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default PaarVacatures
