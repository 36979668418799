import { Form, Input, Select, Slider } from "antd";
import { useTranslation } from "react-i18next";
import {useWindowDimensions} from "../../../hooks/useWindowsDimensions";
const { Option } = Select;

export interface Step6FormValues {
  skills: {
    dutch: number;
    french: number;
    english: number;
    german: number;
    otherLanguages?: string;
    generalSkills: string[] | string;
    otherGeneralSkills?: string;
    toolsAndTech: string[] | string;
    otherToolsAndTech?: string;
    educationDegree: string;
    studies?: string;
    importantResponsabilities?: string;
    bestAchivements?: string;
  };
}

const Step6 = ({
  form,
  onFinishStep6,
  step6Values,
}: {
  form: any;
  onFinishStep6: any;
  step6Values?: Step6FormValues;
}) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const layout =
    width && width > 1400
      ? {
          labelCol: { span: 9 },
          wrapperCol: { span: 15 },
        }
      : {
          labelCol: { span: 8 },
          wrapperCol: { span: 16 },
        };
  return (
    <Form
      {...layout}
      form={form}
      initialValues={step6Values ? step6Values : undefined}
      onFinish={onFinishStep6}
      name="skills-form"
    >
      <div
        style={
          width && width > 1400
            ? {
                display: "flex",
                flexDirection: "row",
                margin: "15px",
              }
            : { display: "flex", flexDirection: "column", margin: "15px" }
        }
      >
        <div style={{ width: "100%" }}>
          <h2 style={{ marginBottom: "15px", marginTop: "15px" }}>
            {t("Talen")}
          </h2>
          <Form.Item
            name={["skills", "dutch"]}
            label={t("Nederlands")}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValue={0}
          >
            <Slider
              step={25}
              marks={{
                0: t("Geen"),
                25: t("Basiskennis"),
                50: t("Goed"),
                75: t("Zeer Goed"),
                100: t("Moedertaal"),
              }}
              style={{ width: "80%", marginLeft: "15px" }}
            />
          </Form.Item>
          <Form.Item
            name={["skills", "french"]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            label={t("Frans")}
            initialValue={0}
          >
            <Slider
              style={{ width: "80%", marginLeft: "15px" }}
              step={25}
              marks={{
                0: t("Geen"),
                25: t("Basiskennis"),
                50: t("Goed"),
                75: t("Zeer Goed"),
                100: t("Moedertaal"),
              }}
            />
          </Form.Item>
          <Form.Item
            name={["skills", "english"]}
            label={t("Engels")}
            initialValue={0}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Slider
              style={{ width: "80%", marginLeft: "15px" }}
              step={25}
              marks={{
                0: t("Geen"),
                25: t("Basiskennis"),
                50: t("Goed"),
                75: t("Zeer Goed"),
                100: t("Moedertaal"),
              }}
            />
          </Form.Item>
          <Form.Item
            initialValue={0}
            name={["skills", "german"]}
            label={t("Duits")}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Slider
              style={{ width: "80%", marginLeft: "15px" }}
              step={25}
              marks={{
                0: t("Geen"),
                25: t("Basiskennis"),
                50: t("Goed"),
                75: t("Zeer Goed"),
                100: t("Moedertaal"),
              }}
            />
          </Form.Item>

          <Form.Item
            name={["skills", "otherLanguages"]}
            label={t("Overige talen")}
          >
            <Input.TextArea />
          </Form.Item>

          <h2 style={{ marginBottom: "15px", marginTop: "15px" }}>
            {t("Vaardigheden")}
          </h2>
          <Form.Item
            name={["skills", "generalSkills"]}
            label={t("Algemene vaardigheden")}
            rules={[
              {
                required: true,
                message: t("Gelieve uw keuze te maken") ?? "",
              },
            ]}
          >
            <Select mode="multiple" placeholder={t("Maak uw keuze")}>
              <Option value="programmatie">{t("Programmatie")}</Option>
              <Option value="boekhouding">{t("Boekhouding")}</Option>
              <Option value="administratie">{t("Administratie")}</Option>
              <Option value="verkoop">{t("Verkoop")}</Option>
              <Option value="marketing">{t("Marketing")}</Option>
              <Option value="leidinggeven">{t("Leidinggeven")}</Option>
              <Option value="klantenservice">{t("Klantenservice")}</Option>
              <Option value="projectmanagement">
                {t("Projectmanagement")}
              </Option>
              <Option value="handenarbeid">{t("Handenarbeid")}</Option>
              <Option value="chauffeur">{t("Chauffeur")}</Option>
              <Option value="overige">{t("Other")}</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name={["skills", "otherGeneralSkills"]}
            label={t("Overige vaardigheden")}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name={["skills", "toolsAndTech"]}
            label={t("Gebruikte tools en technologieën")}
            rules={[
              {
                required: true,
                message: t("Gelieve uw keuze te maken") ?? "",
              },
            ]}
          >
            <Select mode="multiple" placeholder={t("Maak uw keuze")}>
              <Option value="Microsoft Office">
                Microsoft Office Suite (Word, Excel, PowerPoint, etc.)
              </Option>
              <Option value="Google Workspace">
                Google Workspace (Docs, Sheets, Slides, etc.)
              </Option>
              <Option value="Adobe Creative Suite">
                Adobe Creative Suite (Photoshop, Illustrator, InDesign, etc.)
              </Option>
              <Option value="Programming languages">
                Programming languages (Java, Python, C++, etc.)
              </Option>
              <Option value="Web development">
                Web development (HTML, CSS, JavaScript, etc.)
              </Option>
              <Option value="Social media platforms">
                Social media platforms (Facebook, Twitter, LinkedIn, etc.)
              </Option>
              <Option value="overige">{t("Overige")}</Option>

              <Option value="geen">{t("Geen")}</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name={["skills", "otherToolsAndTech"]}
            label={t("Overige tools en technologieëns")}
          >
            <Input.TextArea />
          </Form.Item>
        </div>
        <div style={{ width: "100%" }}>
          <h2 style={{ marginBottom: "15px", marginTop: "15px" }}>
            {t("Opleiding")}
          </h2>
          <Form.Item
            name={["skills", "educationDegree"]}
            label={t("Onderwijs niveau")}
            rules={[
              {
                required: true,
                message: t("Gelieve uw keuze te maken") ?? "",
              },
            ]}
          >
            <Select placeholder={t("Maak uw keuze")}>
              <Option value="basisschool">{t("Basisschool")}</Option>
              <Option value="secundair">{t("Secundair")}</Option>
              <Option value="bachelor">{t("Bachelor")}</Option>
              <Option value="master">{t("Master")}</Option>
              <Option value="andere">{t("Anders")}</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name={["skills", "studies"]}
            label={t("Opleidingen/studies")}
            help={t("Graag specificieren in 'Anders' geselecteerd")}
          >
            <Input.TextArea rows={4} />
          </Form.Item>

          <h2 style={{ marginBottom: "15px", marginTop: "15px" }}>
            {t("Opleiding")}
          </h2>
          <Form.Item
            name={["skills", "importantResponsabilities"]}
            label={t("Belangerijkse verantwoordelijkheden")}
            style={{ padding: "15px" }}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item
            name={["skills", "bestAchivements"]}
            label={t("Belangerijkste prestaties en resultaten")}
            style={{ padding: "15px" }}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

export default Step6;
