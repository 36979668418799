import React from "react";
import { useTranslation } from "react-i18next";
import { i3_sollicitatie_formulier, tables } from "../../../api/types";
import { useQuery } from "@tanstack/react-query";
import i18n from "../../../i18n";
import { fetchStrapiData } from "../../../api/calls";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { Step2FormValues } from "./Step2";
import { Button } from "antd";
import { SendOutlined } from "@ant-design/icons";
import { colorPaller } from "../../../constants/colors"
const Step7 = ({
    step2Values,
    continueForm,
    stopForm,
    endFormReached,
  }: {
    step2Values?: Step2FormValues;
    continueForm?: () => void;
    stopForm?: () => void;
    endFormReached?: boolean;
  }) => {
    const { t } = useTranslation();
    const [data, setdata] = React.useState<i3_sollicitatie_formulier>();
    const { data: response } = useQuery<
      i3_sollicitatie_formulier,
      any,
      { attributes: i3_sollicitatie_formulier },
      [string, tables]
    >([i18n.language, "i3-sollicitatie-formulier"], () =>
      fetchStrapiData({ table: "i3-sollicitatie-formulier" })
    );
    React.useEffect(() => {
      if (response) {
        setdata(response.attributes);
      }
    }, [response]);
  return (
<div style={{ fontWeight: "bold", marginBottom: "15px" }}>
      <h2>
        {t("Bedankt")} {step2Values?.user?.firstName[0].toUpperCase()}
        {step2Values?.user?.firstName.slice(1)?.toLowerCase()}{" "}
        {step2Values?.user?.lastName[0].toUpperCase()}
        {step2Values?.user?.lastName.slice(1)?.toLowerCase()}
      </h2>

      <ReactMarkdown>{data?.outro ?? ""}</ReactMarkdown>


        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "row",
          }}
        >
          <Button
            onClick={stopForm}
            style={{
              width: "30%",
              height: "auto",
              backgroundColor: colorPaller.succes_green,
              color: colorPaller.white,
            }}
          >
            <div>
              <SendOutlined style={{ fontSize: "25px" }} />{" "}
            </div>
            <b>{t("Indienen")}</b>
          </Button>

        </div>
      
    </div>  )
}

export default Step7