import React, { useState } from "react";
import { Button, message, Steps, theme, Form } from "antd";
import Step1 from "./Step1";
import { colorPaller } from "../../../constants/colors";
import Step2, { Step2FormValues } from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5, { Step5FormValues } from "./Step5";
import Step6, { Step6FormValues } from "./Step6";
import { useTranslation } from "react-i18next";
import {useWindowDimensions} from "../../../hooks/useWindowsDimensions";
import { strapiApi } from "../../../api";
import { useNavigate } from "react-router-dom";

import { Dayjs } from "dayjs";

import Step7 from "./Step7";
import { notifTypes } from "../../../App";

const Overview = ({
  openNotificationWithIcon,
}: {
  openNotificationWithIcon: (type: notifTypes) => void;
}) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const [step2form] = Form.useForm();
  const [step2Values, setstep2Values] = useState<Step2FormValues>();
  const [step3form] = Form.useForm();
  const [strapiId, setstrapiId] = useState<number>();
  const [endFormReached] = useState<boolean>(false);
  const [loading, setloading] = useState<boolean>(false);
  const [step5Values, setstep5Values] = useState<Step5FormValues>();
  const [step5form] = Form.useForm();
  const [step6Values, setstep6Values] = useState<Step6FormValues>();
  const [step6form] = Form.useForm();
  const navigate = useNavigate();

  const [continueFormState, setContinueFormState] = useState<boolean>(false);

  const steps = [
    {
      title: t("Welkom"),
    },
    {
      title: t("Persoonlijk"),
    },

    {
      title: t("Bijlagen"),
    },
    {
      title: t("Keuze"),
    },
    ...(continueFormState
      ? [
          {
            title: t("Voorkeur"),
          },
          {
            title: t("Vaardigheden"),
          },
          {
            title: t("Einde"),
          },
        ]
      : []),
  ];
  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);

  const onFinishStep5 = async (values: Step5FormValues) => {
    if (loading || !strapiId) return;
    setloading(true);
    console.log("step5Values", values);
    setstep5Values(values);
    //map dayjs to string
    let formatDate = values.work.preferredStartDate as Dayjs;
    values.work.preferredStartDate = formatDate.format("DD/MM/YYYY");
    //stringify arrays
    values.work.preferredSector = JSON.stringify(values.work.preferredSector);
    values.work.preferredSalary = JSON.stringify(values.work.preferredSalary);
    values.work.possibleAvailabilities = JSON.stringify(
      values.work.possibleAvailabilities
    );

    try {
      let { data } = await strapiApi({
        method: "PUT",
        url: `/i3-sollicitaties/${strapiId}`,
        data: { data: values.work },
      });
      console.log("responseData", data);
    } catch (error) {
      message.error(t("Er is een fout opgetreden"));
    }
    setCurrent((current) => current + 1);
    setloading(false);
  };
  const onFinishStep6 = async (values: Step6FormValues) => {
    if (loading || !strapiId) return;
    setloading(true);
    console.log("step6Values", values);
    setstep6Values(values);

    //stringify arrays
    values.skills.generalSkills = JSON.stringify(values.skills.generalSkills);
    values.skills.toolsAndTech = JSON.stringify(values.skills.toolsAndTech);
    try {
      let { data } = await strapiApi({
        method: "PUT",
        url: `/i3-sollicitaties/${strapiId}`,
        data: { data: values.skills },
      });
      console.log("responseData", data);
    } catch (error) {
      message.error(t("Er is een fout opgetreden"));
    }
    setCurrent((current) => current + 1);
    setloading(false);
  };
  const onFinishStep2 = async (values: Step2FormValues) => {
    if (loading) return;
    setloading(true);
    console.log("step2Values", values);
    setstep2Values(values);
    //map dayjs to string
    let formatDate = values.user.birthDate as Dayjs;
    values.user.birthDate = formatDate.format("DD/MM/YYYY");
    values.user.adressHouseNumber = values.user.adressHouseNumber.toString();
    try {
      if (strapiId) {
        let { data } = await strapiApi({
          method: "PUT",
          url: `/i3-sollicitaties/${strapiId}`,
          data: { data: values.user },
        });
        console.log("responseData", data);
      } else {
        let { data } = await strapiApi({
          method: "POST",
          url: `/i3-sollicitaties`,
          data: { data: values.user },
        });
        setstrapiId(data?.data?.id);
        console.log("responseData", data);
      }
    } catch (error) {
      message.error(t("Er is een fout opgetreden"));
    }
    setCurrent((current) => current + 1);
    setloading(false);
  };
  const continueForm = () => {
    setContinueFormState((prev) => !prev);
    next();
  };
  const stopForm = async () => {
    try {
      setloading(true);
      openNotificationWithIcon("endOfSollicitatieForm");
      navigate("/");
      let { data } = await strapiApi({
        method: "put",
        url: `/i3-sollicitaties/${strapiId}`,
        data: { data: { finishedForm: true } },
      });

      console.log("endData", data);
      setloading(false);
    } catch (error) {
      message.error(t("Er is een fout opgetreden"));
    }
  };

  const next = () => {
    switch (current) {
      case 1:
        step2form.submit();
        break;

      case 2:
        if (continueFormState) {
          setCurrent((prev) => prev + 2);
        } else {
          setCurrent((prev) => prev + 1);
        }
        break;
      case 0:
      case 3:
        setCurrent((prev) => prev + 1);
        break;
      case 4:
        step5form.submit();
        break;
      case 5:
        step6form.submit();
        break;

      default:
        break;
    }
  };

  const prev = () => {
    switch (current) {
      case 0:
        break;
      case 4:
        setCurrent((prev) => prev - 2);
        break;
      case 2:
      case 3:
      case 5:
      case 6:
      case 1:
        setCurrent((prev) => prev - 1);
        break;

      default:
        break;
    }
  };

  const contentStyle: React.CSSProperties = {
    // lineHeight: "260px",
    fontSize: 14,
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `3px solid ${colorPaller.blue_gradient_1}`,
    marginTop: width && width > 1400 ? 35 : 5,
    padding: 4,
    width: width && width > 1400 ? "1300px" : "90vw",
    marginLeft: "auto",
    marginRight: "auto",
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [current]);

  return (
    <div>
      {width && width > 1400 ? (
        <Steps
          current={current}
          items={items}
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        />
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              border: `2px solid ${colorPaller.blue_gradient_4}`,
              borderRadius: "10px",
              textAlign: "center",
              width: "400px",
            }}
          >
            <span>
              {t("Step")}:{current + 1}/{items?.length} -{" "}
              <b>{steps[current]?.title}</b>
            </span>
          </div>
        </div>
      )}
      <div style={contentStyle}>
        {
          {
            0: <Step1 />,
            1: (
              <Step2
                form={step2form}
                onFinishStep2={onFinishStep2}
                step2Values={step2Values}
              />
            ),
            2: (
              <Step3
                form={step3form}
                strapiId={strapiId}
                setLoading={setloading}
              />
            ),
            3: (
              <Step4
                step2Values={step2Values}
                continueForm={continueForm}
                stopForm={stopForm}
                endFormReached={endFormReached}
              />
            ),
            4: (
              <Step5
                form={step5form}
                onFinishStep5={onFinishStep5}
                step5Values={step5Values}
              />
            ),
            5: (
              <Step6
                form={step6form}
                onFinishStep6={onFinishStep6}
                step6Values={step6Values}
              />
            ),

            6: <Step7 stopForm={stopForm} />,
          }[current]
        }
      </div>
      <div style={{ marginTop: 24, display: "flex", justifyContent: "center" }}>
        {current > 0  && (
          <Button
            style={{ margin: "0 8px" }}
            disabled={loading}
            onClick={() => prev()}
          >
            {t("Vorige")}
          </Button>
        )}
        {current < 6 && current !== 3 && (
          <Button type="primary"  style={{backgroundColor:colorPaller.blue_primary}} disabled={loading} onClick={() => next()}>
            {t("Volgende")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Overview;
