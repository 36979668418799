/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import w1 from '../../assets/img/icons/w1.png'
import w1_hover from '../../assets/img/icons/w1-hover.png'
import w2 from '../../assets/img/icons/w2.png'
import w2_hover from '../../assets/img/icons/w2-hover.png'
import w3 from '../../assets/img/icons/w3.png'
import w3_hover from '../../assets/img/icons/w3-hover.png'
import w4 from '../../assets/img/icons/w4.png'
import w4_hover from '../../assets/img/icons/w4-hover.png'
import right_arrow from '../../assets/img/icons/right-arrow.png'
import right_arrow_hover from '../../assets/img/icons/right-arrow2.png'
import { colorPaller } from '../../constants/colors'
import { useTranslation } from 'react-i18next'
const WorkProcessArea = () => {
    const { t } = useTranslation()

    return (
        <section className="section work-process-area" id={'werkWijze'} style={{ backgroundColor: colorPaller.blue_gradient_4 }}>
            <div className="container" data-aos="fade-down">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title text-center">
                            <h2 className="title" style={{color:colorPaller.blue_primary}}>{t('Hoe we te werk gaan')}</h2>
                            <div style={{ color: colorPaller.black }}>
                                <p>{t('Laat ons u assisteren bij het vinden van de ideale match!')}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="working-process-content-wrap">
                            <div className="working-col">
                                <div className="working-process-item">
                                    <div className="icon-box">
                                        <div className="inner">
                                            <img className="icon-img" src={w1} alt="Image-HasTech" />
                                            <img className="icon-hover" src={w1_hover} alt="Image-HasTech" />
                                        </div>
                                    </div>
                                    <div className="content">
                                        <h3 className="title" style={{color:colorPaller.blue_primary}}>{t('Schrijf je in!')}</h3>
                                        <p style={{ color: colorPaller.black }}>
                                            {t('Ben je klaar voor de volgende stap in je carrière? Meld je dan aan bij MassaRecruit.')}
                                        </p>
                                    </div>
                                    <div className="shape-arrow-icon">
                                        <img className="shape-icon" src={right_arrow} alt="Image-HasTech" />
                                        <img className="shape-icon-hover" src={right_arrow_hover} alt="Image-HasTech" />
                                    </div>
                                </div>
                            </div>
                            <div className="working-col">
                                <div className="working-process-item">
                                    <div className="icon-box">
                                        <div className="inner">
                                            <img className="icon-img" src={w2} alt="Image-HasTech" />
                                            <img className="icon-hover" src={w2_hover} alt="Image-HasTech" />
                                        </div>
                                    </div>
                                    <div className="content">
                                        <h4 className="title" style={{color:colorPaller.blue_primary}}>{t('Upload je CV!')}</h4>
                                        <p style={{ color: colorPaller.black }}>
                                            {t(
                                                'Wij bieden u kansen voor vaste en flexibele contracten, voor elk ervaringsniveau. Of u nu een student bent, toe bent aan een nieuwe uitdaging of uw eerste werkervaring wil opdoen.'
                                            )}
                                        </p>
                                    </div>
                                    <div className="shape-arrow-icon">
                                        <img className="shape-icon" src={right_arrow} alt="Image-HasTech" />
                                        <img className="shape-icon-hover" src={right_arrow_hover} alt="Image-HasTech" />
                                    </div>
                                </div>
                            </div>
                            <div className="working-col">
                                <div className="working-process-item">
                                    <div className="icon-box">
                                        <div className="inner">
                                            <img className="icon-img" src={w3} alt="Image-HasTech" />
                                            <img className="icon-hover" src={w3_hover} alt="Image-HasTech" />
                                        </div>
                                    </div>
                                    <div className="content">
                                        <h4 className="title" style={{color:colorPaller.blue_primary}}>{t('Kies de job!')}</h4>
                                        <p style={{ color: colorPaller.black }}>
                                            {t(
                                                'Ons doel is om u te matchen met de juiste baan bij één van onze vele klanten, waar uw vaardigheden en talenten optimaal kunnen worden benut.'
                                            )}
                                        </p>
                                    </div>
                                    <div className="shape-arrow-icon">
                                        <img className="shape-icon" src={right_arrow} alt="Image-HasTech" />
                                        <img className="shape-icon-hover" src={right_arrow_hover} alt="Image-HasTech" />
                                    </div>
                                </div>
                            </div>
                            <div className="working-col">
                                <div className="working-process-item">
                                    <div className="icon-box">
                                        <div className="inner">
                                            <img className="icon-img" src={w4} alt="Image-HasTech" />
                                            <img className="icon-hover" src={w4_hover} alt="Image-HasTech" />
                                        </div>
                                    </div>
                                    <div className="content">
                                        <h4 className="title" style={{color:colorPaller.blue_primary}}>{t('Start!')}</h4>
                                        <p style={{color:colorPaller.black}}>
                                            {t(
                                                'Dus waar wacht u nog op? Vul ons sollicitatieformulier in en laat ons u assisteren bij het vinden van de ideale match!'
                                            )}
                                        </p>
                                    </div>
                                    <div className="shape-arrow-icon d-none">
                                        <img className="shape-icon" src={right_arrow} alt="Image-HasTech" />
                                        <img className="shape-icon-hover" src={right_arrow_hover} alt="Image-HasTech" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WorkProcessArea
