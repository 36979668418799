import React, { useState } from "react";
type scrollProps = {
  className: string;
  activeNavClass: string;
  headerBackground: string;
  scrollDuration: string;
  scrollTargetIds?: string[];
  children: any
};

const ScrollSpy2 = ({
  className,
  activeNavClass,
  headerBackground,
  scrollDuration,
  scrollTargetIds,
  children
}: scrollProps) => {
  const [scrollDurationState, ] = useState<number>(
    Number(scrollDuration) || 1000
  );

  const homeDefaultLink = "/";
  const hashIdentifier = "#";

  const easeInOutQuad = (current_time:any, start:any, change:any, duration:any) => {
    current_time /= duration / 2;
    if (current_time < 1)
      return (change / 2) * current_time * current_time + start;
    current_time--;
    return (-change / 2) * (current_time * (current_time - 2) - 1) + start;
  };

  const scrollTo = (start:any, to:any, duration:any) => {
    let change = to - start,
      currentTime = 0,
      increment = 10;

    let animateScroll = () => {
      currentTime += increment;
      let val = easeInOutQuad(currentTime, start, change, duration);
      window.scrollTo(0, val);
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };

    animateScroll();
  };

  const getNavLinkElement = (sectionID:any) => {
    return document.querySelector(
      `a[href='${hashIdentifier}${sectionID}']`
    );
  };

  const getNavToSectionID = (navHref:any) => {
    return navHref.includes(hashIdentifier)
      ? navHref.replace(hashIdentifier, "")
      : "";
  };

  React.useEffect(() => {
    componentDidMount();

    return () => {
      componentWillUnmount();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const componentDidMount = () => {
    if (document.querySelector(`a[href='${homeDefaultLink}']`)) {
      document
        .querySelector(`a[href='${homeDefaultLink}']`)
        ?.addEventListener("click", (event) => {
          event.preventDefault();
          scrollTo(window.pageYOffset, 0, scrollDurationState);
          window.location.hash = "";
        });
    }

    document
      .querySelector("div[data-nav='list']")
      ?.querySelectorAll("a")
      .forEach((navLink) => {
        navLink.addEventListener("click", (event) => {
          event.preventDefault();
          let sectionID = getNavToSectionID(navLink.getAttribute("href"));

          if (sectionID) {
            let scrollTargetPosition =
              document.getElementById(sectionID)?.offsetTop ??
              0 -
                (headerBackground
                  ? document.querySelector("div[data-nav='list']")
                      ?.scrollHeight ?? 0
                  : 0);
            scrollTo(window.pageYOffset, scrollTargetPosition, scrollDurationState);
          } else {
            scrollTo(window.pageYOffset, 0, scrollDurationState);
          }
        });
      });

    window.addEventListener("scroll", scrollSection, true);
  };

  const componentWillUnmount = () => {
    window.removeEventListener("scroll", scrollSection, true);
  };

  const scrollSection = () => {
    let scrollSectionOffsetTop;
    scrollTargetIds?.forEach((sectionID, index) => {
      scrollSectionOffsetTop =
        document.getElementById(sectionID)?.offsetTop ??
        0 -
          (headerBackground
            ? document.querySelector("div[data-nav='list']")?.scrollHeight ?? 0
            : 0);

      if (
        window.pageYOffset >= scrollSectionOffsetTop &&
        window.pageYOffset <
          scrollSectionOffsetTop +
            (document.getElementById(sectionID)?.scrollHeight ?? 0)
      ) {
        getNavLinkElement(sectionID)?.classList.add(activeNavClass);
        (getNavLinkElement(sectionID)?.parentNode as any)?.classList?.add(
          activeNavClass
        );
        clearOtherNavLinkActiveStyle(sectionID);
      } else {
        getNavLinkElement(sectionID)?.classList.remove(activeNavClass);
        (getNavLinkElement(sectionID)?.parentNode as any)?.classList.remove(
          activeNavClass
        );
      }

      if (
        window.innerHeight + window.pageYOffset >= document.body.scrollHeight &&
        index === scrollTargetIds.length - 1
      ) {
        getNavLinkElement(sectionID)?.classList.add(activeNavClass);
        (getNavLinkElement(sectionID)?.parentNode as any)?.classList.add(
          activeNavClass
        );
        clearOtherNavLinkActiveStyle(sectionID);
      }
    });
  };
  const clearOtherNavLinkActiveStyle = (excludeSectionID:any) => {
    scrollTargetIds?.forEach((sectionID, index) => {
      if (sectionID !== excludeSectionID) {
        getNavLinkElement(sectionID)?.classList.remove(activeNavClass);
        (getNavLinkElement(sectionID)?.parentNode as any)?.classList.remove(
          activeNavClass
        );
      }
    });
  };

  return (
    <div data-nav="list" className={className}>
      {children}
    </div>
  );
};

export default ScrollSpy2;
