import React from "react";
import Overview from "./steps/Overview";
import { notifTypes } from "../../App";
import { Helmet } from "react-helmet-async";

const Sollicitatie = ({
  openNotificationWithIcon,
}: {
  openNotificationWithIcon: (type: notifTypes) => void;
}) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Sollicitatieformulier</title>
        <meta
          name="description"
          content="Vul onderstaande formulier in zodat wij u de perfecte uitdaging kunnen bezorgen"
        />
        <link rel="canonical" href="/sollicitatie" />
      </Helmet>
      <section className="section bg-light">
        <Overview openNotificationWithIcon={openNotificationWithIcon} />
      </section>{" "}
    </React.Fragment>
  );
};

export default Sollicitatie;
