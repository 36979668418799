import React, { useContext, useState } from 'react'
import { Navbar, Nav, NavbarToggler, NavItem, NavLink, Collapse, Button } from 'reactstrap'

import ScrollspyNav from './ScrollSpy2'

//Import Images
import logodark from '../../assets/images/logo/logo2.png'
import { useNavigate } from 'react-router'
import { ROUTES } from '../../constants/routes'
import { useTranslation } from 'react-i18next'
import { colorPaller } from '../../constants/colors'
import { UserContext } from '../../contexts/UserContexts'
import { Link, useLocation } from 'react-router-dom'
export type stateProps = {
    navItems: { id: number; idnm: string; navheading: string }[]
    pos: any
    imglight: boolean
    navClass: string
    home: boolean
}

const NavbarPage = () => {
    const [isOpenMenu, setisOpenMenu] = React.useState<boolean>(false)
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { changeLanguage, language } = useContext(UserContext)
    const { pathname } = useLocation()
    const [subscribeScroll, setsubscribeScroll] = useState<boolean>(false)

    const toggle = () => {
        setisOpenMenu((prev) => !prev)
    }

    const navitems = [
        { id: 1, idnm: 'home', navheading: 'Home' },
        { id: 2, idnm: 'werkWijze', navheading: 'Werkwijze' },
        { id: 3, idnm: 'overOns', navheading: 'Over Ons' },
        { id: 4, idnm: 'vacatures', navheading: 'Vacatures' },
    ]
    const stateHome = {
        navItems: navitems,
        pos: document.documentElement.scrollTop,
        imglight: true,
        navClass: 'navbar-light',
        home: true,
    }
    const stateNotHome = {
        navItems: [],
        pos: document.documentElement.scrollTop,
        imglight: false,
        navClass: 'navbar-light nav-sticky',
        home: false,
    }

    const [STATE, setSTATE] = React.useState<stateProps>(stateHome)
    const targetId = STATE?.navItems.map((item) => {
        return item.idnm
    })

    const scrollNavigation = () => {
        var scrollup = document.documentElement.scrollTop
        if (scrollup > STATE.pos) {
            setSTATE((prev) => ({
                ...prev,
                navClass: 'navbar-light nav-sticky',
                imglight: false,
            }))
        } else {
            setSTATE((prev) => ({
                ...prev,
                navClass: 'navbar-light',
                imglight: true,
            }))
        }
    }

    React.useEffect(() => {
        if (pathname === '/') {
            if (!subscribeScroll) {
                window.addEventListener('scroll', scrollNavigation, true)
                setsubscribeScroll((prev) => !prev)
            }
            if (!STATE.home) {
                setSTATE(stateHome)
            }
        }
        if (pathname !== '/') {
            if (subscribeScroll) {
                window.removeEventListener('scroll', scrollNavigation, true)
                setsubscribeScroll((prev) => !prev)
            }
            if (STATE.home) {
                setSTATE(stateNotHome)
            }
        }
        return () => {
            window.removeEventListener('scroll', scrollNavigation, true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    return (
        <React.Fragment>
            <Navbar expand="lg" fixed="top" className={'navbar-custom sticky sticky-dark ' + STATE?.navClass ?? 'navbar-dark'}>
                <img src={logodark} onClick={() => navigate('/')} alt="" className="logo" height="40" />

                <NavbarToggler onClick={toggle}>
                    <i className="mdi mdi-menu"></i>
                </NavbarToggler>

                <Collapse id="navbarCollapse" isOpen={isOpenMenu} navbar>
                    <Nav navbar className="ml-auto navbar-center" id="mySidenav">
                        {!STATE.home &&
                            [
                                { idnm: '/', navheading: t('Home') },
                                { idnm: '/', navheading: t('Werkwijze') },
                                { idnm: '/', navheading: t('Over Ons') },
                                { idnm: '/', navheading: t('Vacatures') },
                            ].map((item, key) => (
                                <NavItem key={`${item.navheading} - ${key}`} style={{ paddingTop: '5px' }}>
                                    <Link className="nav-link" to={item.idnm}>
                                        {' '}
                                        {item.navheading}
                                    </Link>
                                </NavItem>
                            ))}

                        <ScrollspyNav
                            scrollTargetIds={targetId}
                            scrollDuration="200"
                            headerBackground="true"
                            activeNavClass="active"
                            className="navbar-collapse"
                        >
                            {STATE.home &&
                                STATE?.navItems.map((item, key) => (
                                    <NavItem key={`${item.navheading} - ${key}`} className={item.navheading === 'Home' ? 'active' : ''}>
                                        <NavLink href={'#' + item.idnm}> {t(item.navheading)}</NavLink>
                                    </NavItem>
                                ))}
                        </ScrollspyNav>

                        <React.Fragment>
                            <NavItem key={'werkgever'} style={{ paddingTop: '5px' }}>
                                <Link className="nav-link" to={'/werkgever'}>
                                    {' '}
                                    {t('Voor Werkgever')}
                                </Link>
                            </NavItem>
                            <NavItem key={'contact'} style={{ paddingTop: '5px' }}>
                                <Link className="nav-link" to={'/contact'}>
                                    {' '}
                                    {t('Contact')}
                                </Link>
                            </NavItem>
                        </React.Fragment>

                        <NavItem>
                            <Button
                                className=" btn-rounded "
                                style={{
                                    backgroundColor: 'white',
                                    color: colorPaller.blue_primary,
                                    border: `2px solid ${colorPaller.blue_primary}`,
                                    fontWeight: 'bold',
                                }}
                                onClick={() => navigate(ROUTES.SOLLICITATIE)}
                            >
                                {t('Solliciteer')}
                            </Button>
                        </NavItem>

                        <select
                            style={{
                                backgroundColor: 'transparent',
                                border: '0px',
                                fontWeight: 'bold',
                                color: colorPaller.blue_gradient_2,
                                width: '60px',
                                height: '40px',
                                textAlign: 'center',
                                paddingTop: '7px',
                                marginRight: '10px',
                                marginLeft: '20px',

                                float: 'right',
                            }}
                            onChange={(e) => changeLanguage && changeLanguage(e.target.value as 'fr' | 'nl' | 'en')}
                            value={language}
                        >
                            <option value={'nl'}>NL</option>
                            <option value={'fr'}>FR</option>
                            <option value={'en'}>EN</option>
                        </select>
                    </Nav>
                </Collapse>
            </Navbar>
        </React.Fragment>
    )
}

export default NavbarPage
