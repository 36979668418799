import React from 'react'
import { Button } from 'reactstrap'
import { colorPaller } from '../../constants/colors'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'
import { useTranslation } from 'react-i18next'
import { breakpointValues, useWindowDimensions } from '../../hooks/useWindowsDimensions'

export const WerkgeversPage = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { breakpoint } = useWindowDimensions()

    return (
        <React.Fragment>
            <Helmet>
                <title>Voor Werkgevers</title>
                <meta name="description" content="Voor werkgevers hebben wij meerdere opties voor ogen, bij vragen of opmerkingen kunt u ons contacteren" />
                <link rel="canonical" href="/werkgever" />
            </Helmet>
            <section className="section" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div
                    style={{
                        backgroundColor: 'white',
                        color: 'black',
                        maxWidth: '80%',
                    }}
                >
                    <h5 style={{ color: colorPaller.blue_primary }}>Met ons onderscheidend aanbod realiseren we de juiste match voor uw onderneming</h5>

                    <p>
                        MassaRecruit is een wervingsbureau dat gespecialiseerd is in het aanbieden van een breed scala aan profielen zoals ingenieurs,
                        IT-professionals, techniekers,studenten etc. van verschillende leeftijdsgroepen (young graduates tot ervaren profielen).
                    </p>
                    <p> We richten ons specifiek op het aantrekken van getalenteerde professionals uit diverse sectoren van de arbeidsmarkt.</p>
                    <p>Daarnaast beschikken we ook over profielen die bereid zijn om in het buitenland aan de slag te gaan. </p>

                    <p>Indien u meer wilt weten over onze diensten of een specifiek verzoek heeft, aarzel dan niet om contact met ons op te nemen</p>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <div
                            style={{
                                backgroundColor: colorPaller.blue_gradient_4,
                                borderTopLeftRadius: '25px',
                                borderBottomRightRadius: '25px',
                                paddingTop: '20px',
                                paddingBottom: '20px',
                                width: '80%',
                            }}
                        >
                            <h6 style={{ color: 'black', lineHeight: '1.6', textAlign: 'center' }}>OOK LID WORDEN VAN DE MASSARECRUIT FAMILY</h6>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: breakpoint && breakpoint <= breakpointValues.md ? 'center' : 'space-between',
                                    alignItems: 'center',
                                    flexDirection: breakpoint && breakpoint <= breakpointValues.md ? 'column' : 'row',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                }}
                            >
                                <div style={{ color: 'black' }}>
                                    <p>Op zoek naar meer informatie over onze diensten of een specifiek verzoek?</p>
                                </div>
                                <div style={{ width: '175px' }}>
                                    <Button
                                        style={{ border: '2px solid white', color: 'white', backgroundColor: colorPaller.blue_primary }}
                                        block
                                        onClick={() => navigate(ROUTES.CONTACT)}
                                    >
                                        {t('Contact formulier')}
                                    </Button>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: breakpoint && breakpoint <= breakpointValues.md ? 'center' : 'space-between',
                                    alignItems: 'center',
                                    flexDirection: breakpoint && breakpoint <= breakpointValues.md ? 'column' : 'row',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                    marginTop: '15px',
                                }}
                            >
                                <div style={{ color: 'black' }}>
                                    <p> Wenst u zo snel mogelijk iemand aan de lijn?</p>
                                </div>
                                <div style={{ width: '175px' }}>
                                    <Button
                                        style={{ border: '2px solid white', color: 'white', backgroundColor: colorPaller.blue_primary}}
                                        block
                                        onClick={() => (document.location.href = 'tel:+32456202457')}
                                    >
                                        {t('Bel ons')}
                                    </Button>{' '}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
