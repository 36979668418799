import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { i3_vacature, meta_data, tables } from '../../api/types'
import { fetchStrapiData } from '../../api/calls'
import { Button, Col, Radio, Row, Select, Space, Table } from 'antd'
import { colorPaller } from '../../constants/colors'
import { useNavigate } from 'react-router-dom'
import { ColumnsType } from 'antd/es/table'
import Loader from '../../components/loader/Loader'
import { breakpointValues, useWindowDimensions } from '../../hooks/useWindowsDimensions'
import { Option } from 'antd/es/mentions'

const AllVacatures = () => {
    const navigate = useNavigate()
    const [allCategoriesState, setallCategoriesState] = React.useState<{ [v: string]: number }>()
    const [selectedCatgegory, setselectedCatgegory] = React.useState<string>('')
    const { breakpoint } = useWindowDimensions()
    const [vacaturersForSelectedCategory, setvacaturersForSelectedCategory] = React.useState<{ attributes: i3_vacature; id: number }[]>()
    const { data: allCategoriesData, isLoading: isLoading1 } = useQuery<i3_vacature, any, { [v: string]: number }, [tables, string]>(
        ['i3-vacatures', 'allCategories'],
        () => fetchStrapiData({ table: 'i3-vacatures', customRoute: 'all-categories' })
    )
    const { data: AllVacaturesForACategory, isLoading: isloading2 } = useQuery<
        i3_vacature,
        any,
        { data: { attributes: i3_vacature; id: number }[]; meta: meta_data },
        [tables, string]
    >(
        ['i3-vacatures', selectedCatgegory],
        () => fetchStrapiData({ table: 'i3-vacatures', returnMeta: true, query: `filters[categorie][$eqi]=${selectedCatgegory}` }),
        {
            enabled: selectedCatgegory !== '',
        }
    )
    React.useEffect(() => {
        console.log('all categories', allCategoriesData)
        if (allCategoriesData) {
            setallCategoriesState(allCategoriesData)
            setselectedCatgegory(Object.keys(allCategoriesData)[0])
        }
    }, [allCategoriesData])
    React.useEffect(() => {
        console.log('all AllVacaturesForACategory', AllVacaturesForACategory)

        if (AllVacaturesForACategory) {
            console.log('AllVacaturesForACategory', AllVacaturesForACategory.data)
            setvacaturersForSelectedCategory(AllVacaturesForACategory.data)
        }
    }, [AllVacaturesForACategory])
    const columns: ColumnsType<{ attributes: i3_vacature; id: number }> = [
        {
            title: 'Name',
            key: 'main_title',
            render: (_, record) => record.attributes.main_title,
            ellipsis: true,
        },
        ...(breakpoint && breakpoint >= breakpointValues.md
            ? [
                  {
                      title: 'Provincie',
                      render: (_: any, record: { attributes: i3_vacature; id: number }) => record.attributes.provincie,
                      key: 'provincie',
                      ellipsis: true,
                  },
                  {
                      title: 'Stad',
                      render: (_: any, record: { attributes: i3_vacature; id: number }) => record.attributes.client_city,
                      key: 'client_city',
                      ellipsis: true,
                  },
              ]
            : []),
        {
            title: 'Meer info',
            key: 'meer_info',
            render: (_, record) => (
                <Button
                    style={{
                        backgroundColor: colorPaller.blue_primary,
                        color: colorPaller.white,
                    }}
                    onClick={() => navigate(`/vacatures/${record.id}`)}
                >
                    <b>Meer info</b>
                </Button>
            ),
        },
    ]

    return (
        <React.Fragment>
            <Helmet>
                <title>Vacatures</title>
                <meta name="description" content="Een greep uit onze vacatures" />
                <link rel="canonical" href="/vacatures" />
            </Helmet>
            <section className="section  section-few-jobs" style={{ paddingLeft: '15px', paddingRight: '15px', minHeight: '600px' }}>
                {(isLoading1 || isloading2) && <Loader />}
                {allCategoriesState ? (
                    breakpoint && breakpoint >= breakpointValues.lg ? (
                        <Row style={{ paddingTop: '15px' }}>
                            <Col span={1}></Col>
                            <Col span={4}>
                                <div
                                    style={{
                                        border: `2px solid ${colorPaller.blue_gradient_1}`,
                                        backgroundColor: colorPaller.white,
                                        borderRadius: '15px',
                                        paddingBottom: '15px',
                                        paddingTop: '15px',
                                    }}
                                >
                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                        <h5> Categorieën</h5>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Radio.Group onChange={(e) => setselectedCatgegory(e.target.value)} value={selectedCatgegory}>
                                            <Space direction="vertical">
                                                {allCategoriesState && Object.keys(allCategoriesState).map((key) => <Radio value={key}>{key}</Radio>)}
                                            </Space>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </Col>
                            <Col span={1}></Col>
                            <Col span={18}>
                                <div>{vacaturersForSelectedCategory && <Table columns={columns} dataSource={vacaturersForSelectedCategory} />}</div>
                            </Col>
                        </Row>
                    ) : (
                        <React.Fragment>
                            <Row style={{ paddingTop: '15px' }}>
                                <Col span={1}></Col>
                                <Col span={22}>
                                    <div
                                        style={{
                                            border: `2px solid ${colorPaller.blue_gradient_1}`,
                                            backgroundColor: colorPaller.white,
                                            borderRadius: '15px',
                                            paddingBottom: '5px',
                                            paddingTop: '5px',
                                            marginBottom: '5px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div>
                                            <b style={{ marginRight: '15px', color: colorPaller.blue_primary }}>Categorie: </b>
                                            <Select onChange={(e) => setselectedCatgegory(e)} value={selectedCatgegory}>
                                                {allCategoriesState && Object.keys(allCategoriesState).map((key) => <Option value={key}>{key}</Option>)}
                                            </Select>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={1}></Col>
                            </Row>
                            <Row>
                                <Col span={1}></Col>

                                <Col span={22}>
                                    <div>{vacaturersForSelectedCategory && <Table columns={columns} dataSource={vacaturersForSelectedCategory} />}</div>
                                </Col>
                                <Col span={1}></Col>
                            </Row>
                        </React.Fragment>
                    )
                ) : (
                    <></>
                )}
            </section>{' '}
        </React.Fragment>
    )
}

export default AllVacatures
