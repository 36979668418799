import React from "react";
import HomeSection from "../../components/HomeSection/HomeSection";
import PaarVacatures from "../../components/Vacatures/PaarVacatures";
import WorkProcessArea from "../../components/workProcess/WorkProcessArea";
import AboutUs from "../../components/AboutUs/AboutUs";
import Abboneer from "../../components/Abboneer/Abboneer";
import { notifTypes } from "../../App";
import { Helmet } from "react-helmet-async";

const Home = ({
  openNotificationWithIcon,
}: {
  openNotificationWithIcon: (t: notifTypes) => void;
}) => {


  return (
    <React.Fragment>
      <Helmet>
        <title>Massarecruit</title>
        <meta name="description" content="Als toonaangevend recruitmentbureau zijn wij bij MassaRecruit voortdurend op zoek naar getalenteerde individuen uit alle hoeken van de arbeidsmarkt"/>
        <link rel="canonical" href="https://massarecruit.com"/>
      </Helmet>
      {/* landing */}
      <HomeSection />

      {/* hoe werken wij */}
      <WorkProcessArea />

      {/* over ons met banner */}
      <AboutUs />

      {/* Paar vacatures */}
      <PaarVacatures />


      {/* Importing Sunscribe */}
      <Abboneer openNotificationWithIcon={openNotificationWithIcon} />

      {/* Importing Footer */}
    </React.Fragment>
  );
};

export default Home;
