import React from 'react'

import { Helmet } from 'react-helmet-async'
import { icookiesSettings } from '../../App'
import { Button, Card, Col, Divider, Row } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
const CookesBeleidPage = ({
  removeCookie,
    cookiesSettings,
}: {
  removeCookie: () => void
    cookiesSettings: icookiesSettings
}) => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Cookiesbeleid</title>
                <meta name="robots" content="noindex" />
                <meta name="description" content="Onze interne privacy beleid, voor vragen of merkingen kunt u ons contacteren" />
                <link rel="canonical" href="/privacy-beleid" />
            </Helmet>
            <div style={{ margin: '30px', paddingTop: '50px' }}>
                <h4>Cookies beleid</h4>
                <p>
                    Dankzij cookies hoef je niet steeds dezelfde informatie in te voeren wanneer je bij ons terugkomt. Lekker handig! Bovendien helpen ze ons
                    inzien hoe je onze website gebruikt en hoe wij de boel beter en klantvriendelijker kunnen maken. Daarnaast maken we gebruik van JavaScript.
                    Hiermee kunnen we mooie dingen doen.
                </p>
                <p>
                    Als je onze website in volle glorie wilt gebruiken, dan is het nodig dat je onze cookies accepteert. Gelukkig is dat heel makkelijk.
                    Natuurlijk gaan we voorzichtig om met de gegevens die we van je verzamelen. Je kunt zelf aangeven wat we van je mogen volgen, door voor het
                    Standaard- of Persoonlijke cookie-pakket te kiezen.
                </p>
                <b style={{color:'black'}}><u>Huidige Cookie instellingen:</u></b>
                {cookiesSettings && (
                    <Row>
                        <Col span={5}>
                            <b>laatste wijziging:</b> <p>{cookiesSettings.date  as string}</p>
                        </Col>
                        <Col span={5}>
                            <b>Functionel:</b>
                            <p>{cookiesSettings.functional?'Ja':'Nee'}</p>
                        </Col>
                        <Col span={5}>
                            <b>Analytisch:</b>
                            <p>{cookiesSettings.analytics?'Ja':'Nee'}</p>
                        </Col>
                        {cookiesSettings.lang && (
                            <Col span={5}>
                                <b>Taal:</b>
                                <p>{cookiesSettings.lang}</p>
                            </Col>
                        )}
                        <Col span={4}>
                          <Button danger type='primary' onClick={removeCookie}> 
                          <DeleteOutlined />
                          </Button>
                        </Col>
                    </Row>
                )}

                <Card title={'Functionele Cookies'}>
                    <b>Functionele toestemming</b>{' '}
                    <p>
                        Dit is de instelling die we bijhouden waarin jou toestemming zit opgeslagen of wij al dan niet de functionele cookies mogen gebruiken
                        Hierdoor hoef je bijvoorbeeld een formulier niet opnieuw in te vullen als je een pagina herlaadt, of niet alle velden in één keer juist
                        invult.
                    </p>
                    <Row>
                        <Col span={8}>
                            <b>Eigenschappen</b> <p>Opgeslagen in Local Storage onder cookies_settings </p>
                        </Col>
                        <Col span={8}>
                            {' '}
                            <b>Naam</b> <p>functional </p>
                        </Col>
                        <Col span={8}>
                            {' '}
                            <b>Delen</b> <p>Wij delen deze toestemmiong niet met derden </p>
                        </Col>
                    </Row>
                    <Divider />
                    <b>Analytische toestemming</b>{' '}
                    <p>
                        Dit is de instelling die we bijhouden waarin jou toestemming zit opgeslagen of wij al dan niet de analytische cookies mogen gebruiken.
                    </p>
                    <Row>
                        <Col span={8}>
                            <b>Eigenschappen</b> <p>Opgeslagen in Local Storage onder cookies_settings </p>
                        </Col>
                        <Col span={8}>
                            {' '}
                            <b>Naam</b> <p>analytics </p>
                        </Col>
                        <Col span={8}>
                            {' '}
                            <b>Delen</b> <p>Wij delen deze toestemming niet met derden </p>
                        </Col>
                    </Row>
                    <Divider />
                    <b>Datum laatste wijziging</b>{' '}
                    <p>
                        Onze Cookies instellingen blijven 1 jaar geldig na laatste wijziging. Na 1 jaar worden deze verwijderd en vragen wij u opnieuw of wij
                        deze mogen gebruiken.
                    </p>
                    <Row>
                        <Col span={8}>
                            <b>Eigenschappen</b> <p>Opgeslagen in Local Storage onder cookies_settings </p>
                        </Col>
                        <Col span={8}>
                            {' '}
                            <b>Naam</b> <p>date </p>
                        </Col>
                        <Col span={8}>
                            {' '}
                            <b>Delen</b> <p>Wij delen deze datum niet met derden </p>
                        </Col>
                    </Row>
                    <Divider />
                    <b>Taal</b>
                    <p>Dit is de taalsvoorkeur zodat u dit niet telkens opnieuw moet instellen bij een nieuwe sessie.</p>
                    <Row>
                        <Col span={8}>
                            <b>Eigenschappen</b> <p>Opgeslagen in Local Storage onder cookies_settings </p>
                        </Col>
                        <Col span={8}>
                            {' '}
                            <b>Naam</b> <p>lang </p>
                        </Col>
                        <Col span={8}>
                            {' '}
                            <b>Delen</b> <p>Wij delen deze taal niet met derden </p>
                        </Col>
                    </Row>
                </Card>
                <Card title={'Analytische Cookies'}>
                    <b>Google Analytics</b>{' '}
                    <p>
                        Met Google Analytics meten we hoe je massarecruit.com gebruikt. Op basis hiervan kunnen wij de gebruikservaring van onze website optimaliseren.
                    </p>
                    <Row>
                        <Col span={8}>
                            <b>Eigenschappen</b> <p>Opgeslagen in Cookies </p>
                        </Col>
                        <Col span={8}>
                            {' '}
                            <b>Namen</b> <p>utma, utmb, utmc, utmv en utmz</p>
                        </Col>
                        <Col span={8}>
                            {' '}
                            <b>Delen</b> <p>Google deelt geen anonieme data met derden. </p>
                        </Col>
                    </Row>
                </Card>
            </div>
        </React.Fragment>
    )
}

export default CookesBeleidPage
